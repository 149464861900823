<template>
    <div class="card">
        <div class="card-content pb-1 h-100">
            <div class="col-12 report-card tagcolor"></div>
            <div class="pt-1" v-if="title">
                {{title}}
            </div>
            <div v-if="typeof value != 'undefined'" class="header-amount">
                <span v-if="isAmt">₱</span>
                {{ value | numeralFormat('0,0.[00]') }}
                <small v-if="isPoint">point</small>
                <slot name="button"></slot>
            </div>
            <div v-if="startDatetime&&endDatetime">
                {{ $datetimeFormat(startDatetime,'tableDate')}} - {{ $datetimeFormat(endDatetime,'tableDate') }}
            </div>
            <slot name="date"></slot>
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>

export default {
    props: ["title", "value", "startDatetime", "endDatetime", "isAmt", "isPoint"],
    data() {
        return {
        };
    },
};
</script>

<style scoped>
    .card {
        margin-bottom: 1rem;
    }
    .header-amount{
        font-size: 2em;
    }
    .report-card {
		border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        padding-top: 3px;
		height: 0.2rem;
    }
    .report-card.tagcolor {
		background-color: #1ba3ce;
	}
</style>
