import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"card-title"},[_vm._v("Payment")]),_c(VAlert,{attrs:{"border":"left","colored-border":"","elevation":"1"}},[_vm._v(" These values will apply within 5~10 minutes. ")]),(_vm.page==='info')?[_c(VSimpleTable,{staticClass:"mt-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Payment")]),_c('th',{staticClass:"text-left"},[_vm._v("Payment Type")]),_c('th',{staticClass:"text-left"},[_vm._v("Fee")]),_c('th',{staticClass:"text-left"},[_vm._v("Policy")])])]),_c('tbody',[_vm._l((_vm.selected),function(pgChannel,pI){return [_c('tr',{key:pI},[_c('td',[_vm._v(_vm._s(pgChannel.pgChannelName))]),_c('td',[_vm._v(_vm._s(_vm.changePgChannelPeriodType(pgChannel.pgChannelPeriodType)))]),_c('td',[_vm._v(_vm._s(_vm.feeFormat(pgChannel)))]),_c('td',[_vm._v(_vm._s(_vm.feePolicyFormat(pgChannel)))])])]})],2)]},proxy:true}],null,false,2788727486)}),(!_vm.canConfirm)?_c(VBtn,{attrs:{"color":_vm.btnSet.modify.color,"outlined":""},on:{"click":function($event){return _vm.goEditPage('payment')}}},[_vm._v("Modify")]):_vm._e()]:(_vm.page==='modify')?[_c(VForm,{ref:"form",staticClass:"form"},[_c(VDataTable,{staticClass:"payment-table",attrs:{"headers":_vm.headers,"items":_vm.pgChannelList,"single-select":false,"item-key":"pgChannel","items-per-page":20,"show-select":"","hide-default-footer":"","mobile-breakpoint":"0"},on:{"change":function($event){return _vm.onChange(_vm.selected)}},scopedSlots:_vm._u([{key:"item.feeRate",fn:function(ref){
var item = ref.item;
return [_c("payment-fee-extra-editor",{tag:"div",attrs:{"item":item,"selected":_vm.selected}})]}},{key:"item.feePolicy",fn:function(ref){
var item = ref.item;
return [_c(VSelect,{attrs:{"items":_vm.feePolicyList,"item-text":"name","item-value":"value","outlined":"","dense":"","hide-details":""},model:{value:(item.feePolicy),callback:function ($$v) {_vm.$set(item, "feePolicy", $$v)},expression:"item.feePolicy"}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"mx-auto pt-3",staticStyle:{"max-width":"600px"}},[(!_vm.canConfirm)?_c(VBtn,{attrs:{"color":_vm.btnSet.modify.color,"outlined":"","block":""},on:{"click":function($event){return _vm.onSubmitPayment('payment')}}},[_vm._v("Modify")]):_vm._e()],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }