<template>
    <!-- BEGIN: Header-->
    <div>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <nav class="header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light navbar-shadow">
            <div class="navbar-wrapper">
                <div class="navbar-container content">
                    <div class="navbar-collapse" id="navbar-mobile">
                        <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                            <ul class="nav navbar-nav">
                                <li class="nav-item mobile-menu d-xl-none mr-auto">
                                    <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#">
                                        <i class="feather icon-menu"></i>
                                    </a>
                                </li>
                            </ul>
                            <div>
                                <h3 class="d-none d-md-block d-lg-block content-header-title float-left d-sm-none ml-1 mb-0">{{ subject }}</h3>
                            </div>
                        </div>
                        <ul class="nav navbar-nav float-right">
                            <li class="dropdown dropdown-user nav-item">
                                <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                    <div class="user-nav d-sm-flex d-none mr-0">
                                        <span class="user-status" v-text="role"></span>
                                        <span class="user-name text-bold-600" style="margin-bottom:.234rem;margin-top:.2rem;" v-text="userName"></span>
                                        <span class="user-status font-small-1" v-text="userId"></span>
                                    </div>
                                    <!-- <span>
                                        <span class="round text-center" style="border:1px solid gray; background-color:#EEE; width:40px; height:40px; display:inline-block; padding-top:6px;">
                                            <i class="round feather icon-user mr-0" style="font-size:2em;"></i>
                                        </span>
                                    </span> -->
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item" href="" v-on:click.prevent="onProfileButton">
                                        <i class="feather icon-user"></i>&nbsp;Edit Profile
                                    </a>
                                    <a class="dropdown-item" href="" v-on:click.prevent="onTestBadRequest">
                                        <i class="feather icon-user"></i>&nbsp;Test 400(BadRequest) 
                                    </a>
                                    <a class="dropdown-item" href="" v-on:click.prevent="onTestUnAuthorized">
                                        <i class="feather icon-user"></i>&nbsp;Test 401(UnAuthorized)
                                    </a>
                                    <a class="dropdown-item" href="" v-on:click.prevent="onTestRestricted">
                                        <i class="feather icon-user"></i>&nbsp;Test 403(Restricted)
                                    </a>
                                    <a class="dropdown-item" href="" v-on:click.prevent="onTestNotFound">
                                        <i class="feather icon-user"></i>&nbsp;Test 404(NotFound)
                                    </a>
                                    <a class="dropdown-item" href="" v-on:click.prevent="onTestServerError">
                                        <i class="feather icon-user"></i>&nbsp;Test 500(Server) 
                                    </a>
                                    
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="" v-on:click.prevent="onTestGotoLogin">
                                        <i class="feather icon-power"></i>&nbsp;Test gotoLogin 
                                    </a>
                                </div>
                            </li>
                            <li class="dropdown dropdown-user nav-item">
                                <a class="dropdown-toggle nav-link dropdown-user-link pl-0" href="">
                                    <button type="button" 
                                        class="btn btn-danger waves-effect waves-light d-inline-flex"
                                        v-on:click.stop.prevent="onClickLogout">
                                        Logout
                                    </button>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
    <!-- END: Header-->
</template>
<script>
var w = window;

export default {
    props : [
        'subject'
    ],
    data: function () {
        return {
            logon : false,
            userNo : 0,
            userName : null,
            role : 'Class',
            userId : null
        }
    },
    watch : {
    },
    mounted : function(){
        this.loadLogonProfile(this);
    },
    methods : {
        onTestBadRequest : function(){
            $(w).trigger('EVENT_NOTIFICATION_API_BADREQUEST');
        },
        onTestUnAuthorized : function(){
            $(w).trigger('EVENT_NOTIFICATION_API_UNAUTHORIZED');
        },
        onTestRestricted : function(){
            $(w).trigger('EVENT_NOTIFICATION_API_RESTRICTED');
        },
        onTestNotFound : function(){
            $(w).trigger('EVENT_NOTIFICATION_API_NOTFOUND');
        },
        onTestServerError : function(){
            $(w).trigger('EVENT_NOTIFICATION_API_SERVERERROR');
        },
        onProfileButton : function(){
            var uri = "/account/admin-profile-edit.html";
            (new RouteUtil(uri)).param({ userNo: this.userNo }).go();
        },
        onClickLogout : function(){
            $(w).trigger("EVENT_LOGOUT");
        },
        onTestGotoLogin : function(){
            (new RouteUtil('/sign/signin.html')).go();
        },
        loadLogonProfile(vue){
            vue.logon = false;
            vue.userNo = 0;
            vue.userName = 'Unknown';
            vue.userId = 'Unknown';

            var data = w.AUTHENTICATION || {};
            vue.logon = (typeof data.hqUserSeq !== 'undefined');
            vue.userNo = data.no;
            vue.userName = data.name;
            vue.userId = data.userId;
            vue.role = data.className;

            if( ! vue.logon ){
                $(window).trigger('EVENT_NOTIFICATION_API_UNAUTHORIZED');
            }
        }
    },
}
</script>
<style scoped>

</style>