<template>
    <i :class="cIcon+' pl-1'" :style="'color:'+cColor" aria-hidden="true"></i>
</template>

<script>
export default {
    props: ['stepStatus'],
    data: function() {
        return {
            iconSet : {
                'D' : {
                    'icon': '',
                    'color': '',
                },
                'W' : {
                    'icon': 'fa fa-exclamation-circle',
                    'color': 'blueviolet',
                },
                'R' : {
                    'icon': 'fa fa-times-circle',
                    'color': 'red',
                },
                'C' : {
                    'icon': 'fas fa-check-circle',
                    'color': 'skyblue',
                },
            }
        }
    },
    computed: {
        cIcon() {
            return this.iconSet[this.stepStatus].icon;
        },
        cColor() {
            return this.iconSet[this.stepStatus].color;
        }
    },
    methods: {
    }
}
</script>

<style>
    .i, .fas, .fa {
        display: flex;
        align-items: center;
    }
</style>