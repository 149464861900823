import Vue from 'vue'

require('@/assets/sti/lib/vue/vue-numerals.js');
require('@/assets/sti/lib/vue/vue-sti-db-trans.js');
require('@/assets/sti/lib/vue/vue-sti-formatter.js');
require('@/assets/sti/lib/vue/vue-nas-file.js');

import { apiServer } from '@/assets/sti/lib/vue/sti-api-server';
if( ! window.APP_APISERVER ){
    window.APP_APISERVER = apiServer;
}

Vue.component('form-select-box', () => import('@/components/form-select-box.vue'));
Vue.component('form-date-box', () => import('@/components/form-date-box.vue'));
Vue.component('header-breadcrumb', () => import('@/components/header-breadcrumb.vue'));
Vue.component('table-empty-body', () => import('@/components/table-empty-body.vue'));
Vue.component('table-pagenation', () => import('@/components/table-pagenation.vue'));

// Common Initialize codes
{
    var ap = window.__app_props__ = window.__app_props__ || {};
    ap.BASE_URL = process.env.BASE_URL;
    ap.VUE_APP_PROFILE_CONFIG = JSON.parse(process.env.VUE_APP_PROFILE_CONFIG||"{}");
    ap.VUE_APP_PROFILE_NAME = (process.env.VUE_APP_PROFILE_NAME || 'dev');
}