/**
 * Nas Path maker for biztreats only.
 */
import Vue from 'vue'
import { apiServer } from '@/assets/sti/lib/vue/sti-api-server';
import * as _ from 'lodash'

function _installFilter(){
    if( Vue.prototype.$nasFile ) return;
    var mod = {
        install : function(V){
            var call = function(value){ 
                var lastLink = value;
                // Exceptional case of 'sample' converting
                if( _.startsWith(value, '/static/sample/images') ){
                    lastLink = value;
                    return lastLink;
                }
                return V.prototype.$cdnBaseUrl(lastLink);
            }
            V.filter('nasFile', call);
            Vue.prototype.$nasFile = call;
        }, // end of install
    };
    Vue.use(mod);
}
if( apiServer )
    _installFilter();

export default {};