
import * as _ from "@/plugins/lodash.js";

// PageCache --- How to use
// var pcObj = PageCache;
// pcObj.put('key', {'name':'value'})
// 		.pushToHash()
//		.pullFromHash().get('key')
var PageCache = {
    mem: null,
    getHash: function () {
        try {
            var h = location.hash;
            var tg = h || '{}';
            if (h && h.startsWith('#'))
                tg = h.substr(1);
            return JSON.parse(atob(tg));
        } catch (e) {
            return {};
        }
    },
    setHash: function (json) {
        try {
            var curHref = location.href;
            var hash = location.hash;
            var lFound = curHref.lastIndexOf(hash);
            var repl = location.href;
            if (lFound > 0) {
                var pre = curHref.substr(0, lFound);
                repl = pre + '#' + (json ? btoa(JSON.stringify(json)) : '');
            }
            location.replace(repl);
        } catch (e) {
            //console.error('error..setHash', json);
        }
    },
    put: function (k, v) {
        var tgMem = this.mem;
        if (!tgMem) tgMem = this.mem = {};
        tgMem[k] = v;
        return this;
    },
    get: function (k) {
        if (!this.mem) return null;
        return this.mem[k];
    },
    pullFromHash: function () {
        this.mem = this.getHash();
        return this;
    },
    pushToHash: function () {
        this.setHash(this.mem);
        return this;
    },
}; // PageCache

/*
 * var rUtil = new RouteUtil('../sign/signin.html');
 * console.log('RouteUtil', rUtil.current.param('callback'));
 * console.log('RouteUtil', rUtil.pullSearch().param({'callback2':'abc2'}).url());
 */
var RouteUtil = function (goUri) {
    var getSearchStr = function (sz) {
        if (!sz) return '';
        var idx = sz.indexOf('?');
        if (idx < 0) return '';
        return sz.substr(idx);
    }
    var getPathStr = function (sz) {
        if (!sz) return '';
        var idx = sz.indexOf('?');
        if (idx < 0) return sz;
        return sz.substr(0, idx);
    }
    var searchParse = function (q) {
        var ot = {};
        if (!q) return ot;
        var qt = q;
        while (qt.startsWith('?')) qt = qt.substr(1);
        var kvArr = qt.split("&");
        for (var k = 0; k < kvArr.length; ++k) {
            var pair = kvArr[k];
            var kv = pair.split("=");
            if (kv.length != 2) continue;
            ot[decodeURIComponent(kv[0])] = decodeURIComponent(kv[1]);
        }
        return ot;
    }
    var objToStr = function (kv) {
        if (typeof kv !== 'object') return;
        var exSearch = {};
        var keys = Object.keys(kv);
        for (var k = 0; k < keys.length; ++k) {
            var key = keys[k];
            var value = kv[key];

            if (value) exSearch[key] = value;
        }
        var searchStr = '';
        _.map(exSearch, function (v, k) {
            if (searchStr) searchStr += '&';
            searchStr += (encodeURIComponent(k) + '=' + encodeURIComponent(v));
        });
        return searchStr;
    }

    return {
        // Get Current URL's search keyword
        current: {
            param: function (a) {
                var sp = searchParse(location.search);
                if (typeof a === 'string') {
                    return sp[a];
                }
                if (!a) {
                    return sp;
                }
                return;
            }
        },
        path: getPathStr(goUri),
        search: searchParse(getSearchStr(goUri)),
        url: function () {
            var sz = objToStr(this.search);
            var search = (sz && sz.length > 0) ? ('?' + sz) : '';
            var run = this.path + search;
            return run;
        },
        goBack: function () {
            history.go(-1);
        },
        go: function () {
            location.href = this.url();
        },
        goOrigin: function () {
            var org = this.search['origin'];
            if (org) {
                location.href = org;
                return;
            }
            location.href = this.url();
        },
        setOrigin: function () {
            this.search['origin'] = location.href;
            return this;
        },
        goRedirect: function () {
            var red = this.search['redirect'];
            if (red) {
                location.href = red;
                return;
            }
            location.href = this.url();
        },
        setRedirect: function () {
            this.search['redirect'] = location.href;
        },
        pullSearch: function () {
            var locSearch = location.search;
            Object.assign(this.search, searchParse(locSearch));
            return this;
        },
        param: function (a) {
            if (typeof a === 'string') {
                return this.search[a];
            }
            if (!a) return this.search;

            if (typeof a !== 'object') return;
            var keys = Object.keys(a);
            for (var k = 0; k < keys.length; ++k) {
                var key = keys[k];
                var value = a[key];
                this.search[key] = value;
            }
            return this;
        },
        objectToString: function (a) {
            return objToStr(a);
        }
    }
}; // RouteUtil

// Usage :
// DateUtil.gmtMsec2LocalYyyyMmDd(100000*1000);
// 
var SticDateUtil = {
    // Miliseconds to YYY-MM-DD (Local)
    gmtMsec2LocalYyyyMmDd: function (mSec) {
        var date = new Date(mSec);
        var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];
        return dateString;
    },
    // Input YYYY-MM-DD(LOCAL) to Miliseconds
    localYyyyMmDd2GmtMsec: function (szDate) {
        if (!szDate) return '';

        var szGmtDate = szDate + "T00:00:00Z";
        var date = new Date(szGmtDate);
        var dateString = new Date(date.getTime() + (date.getTimezoneOffset() * 60000))
        return dateString.getTime();
    },
    addDays : function(date, days) {
        const outeDate = new Date(date.valueOf());
        outeDate.setDate(outeDate.getDate() + days);
        return outeDate;
    },
    getDiffMonthBetweenDates: function(date1, date2){
        const year1 = date1.getFullYear();
        const month1 = date1.getMonth();
        const year2 = date2.getFullYear();
        const month2 = date2.getMonth();
        return (year2-year1)*12 +month2-month1;
    },
    addMonths : function(date, months){
        const prevDate = new Date(date.valueOf());
        let nextDate = new Date(date.valueOf());

        nextDate.setMonth(nextDate.getMonth()+months);
        while(this.getDiffMonthBetweenDates(prevDate, nextDate) > months){
            nextDate = this.addDays(nextDate, -1);
        }
        return nextDate;
    },
    subMonths : function(date, months){
        const prevDate = new Date(date.valueOf());
        let nextDate = new Date(date.valueOf());

        nextDate.setMonth(nextDate.getMonth()-months);
        while(this.getDiffMonthBetweenDates(nextDate, prevDate) < months){
            nextDate = this.addDays(nextDate, -1);
        }
        return nextDate;
    },
    isWithinLimit : function(prevDateStr, nextDateStr, months){
        const prevDate = new Date(prevDateStr);
        const nextDate = new Date(nextDateStr);

        let resultDate = this.addMonths(prevDate, months);
        if(prevDate.getDate() === nextDate.getDate()){
            resultDate = this.addDays(resultDate, -1);
        }
        return nextDate.getTime() <= resultDate.getTime()
    }
}; // DateUtil

/*
import {MessageUtil} from '@/plugins/app-util';

var err = {
    'result_msg' : 'WCS_CK_ERRORS_REQUIRED_400_FAILED',
    'fields' : ['name']
};

var result_msg = (err.result_message ? MessageUtil.fnGetMessage(err.result_message, err.fields) : "Something went wrong, we are working on getting this fixed as soon as we can. Please contact support@biztreats.ph");
// error alert. 
vue.$dialog({
    title : 'Sign up error',
    text  :  result_msg,
    type  : 'ERROR',
    button1Text : 'OK',
});
*/
var MessageUtil = {
    fnGetMessageList : function(array) {
        var messageList = [];
    
        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            var fieldName = obj.field_name;
            var validationMessage = obj.validation_message;
            var message = this.fnGetMessage(validationMessage, fieldName);
    
            if (messageList.indexOf(message) == -1) {
                messageList.push(message);
            }
        }
        return messageList;
    },
    fnGetMessage : function(code, fieldName) {
        var message;
        var msg = (fnGetCodeOne && fnGetCodeOne('ERROR_CODE', code)) || '';
    
        if (msg) {
            if(Array.isArray(fieldName)){
                message = this.StringFormat(msg, fieldName);
            }else{
                message = this.StringFormat(msg, [ fieldName ]);
            }
        } else {
            message = _.isEmpty(fieldName) ? code : fieldName + ' : ' + code;
        }
    
        return message;
    },
    /**
     * @example var value = StringFormat("{0}, {1}, {2} ...{...}", ["DATA1",
     *		  "DATA2", "DATA3"]);
     * @returns
     */
    StringFormat : function(strFormatString, arrDataArg) {
        var expression = strFormatString;
        for (var i = 0; i < arrDataArg.length; i++) {
            var prttern = "{" + (i) + "}";
            expression = this.replaceAll(expression, prttern, arrDataArg[i]);
        }

        return expression;
    },
    /**
     * @description Replace string text in all region
     * @param search
     * @param replacement
     * @returns Result of replacement string text
     */
    replaceAll : function(target, search, replacement) {
        return target.split(search).join(replacement).trim();
    },
    // EXAMPLE ---------------------------------------------------------------------
    // FAIL ---------------------------------------------------------------------
    //     var fail_dialog_title = 'Sign in fail';
    //     var handled = MessageUtil.showFailMessage(vue, data, fail_dialog_title, 'WARNING');
    //     if( handled ) return;
    // ERROR ---------------------------------------------------------------------
    //     var err_dialog_title = 'Sign up error';
    //     MessageUtil.showFailMessage(vue, err, err_dialog_title, 'ERROR');
    showFailMessage(vue, data, dialog_title, dialog_type) {
        var result_code = data.result_code;
        var result_message = "Something went wrong, we are working on getting this fixed as soon as we can. Please contact support@biztreats.ph";

        if (dialog_type == 'ERROR') {
            dialog_title = 'Oops! Something Went wrong';
        }

        if (result_code === 200 ) {
            return false;
        } else if(result_code === 400) { 
            result_message = MessageUtil.fnGetMessageList(data.data)[0] || result_message; 
        } else {
            result_message = (data.result_message ? MessageUtil.fnGetMessage(data.result_message) : result_message);
        }
        vue.$dialog({
            title : dialog_title,
            text  : result_message,
            type  : dialog_type,
            button1Text : 'OK',
        }); 
        return true;
    },
    // SUCCESS ---------------------------------------------------------------------
    //     var redirect_uri = (data.data && data.data.redirect_uri) || '/index.html';
    //     var button1Action = () => {
    //         location.href = redirect_uri;
    //     }
    //     MessageUtil.showSuccessMessage(vue, data, 'Sign in success', 'Log in Success', button1Action);
    //     MessageUtil.showSuccessMessage(vue, data, 'Sign in success', 'Log in Success'); // if button1Action is nullable, 
    showSuccessMessage(vue, data, dialog_title, dialog_message, button1Action) {
        var result_message = (data.result_message ? MessageUtil.fnGetMessage(data.result_message) : dialog_message);
        // success alert.
        vue.$dialog({
            title : dialog_title,
            text  : result_message,
            type  : 'CONFIRM',
            button1Text : 'OK',
            button1Action : button1Action,
        }); 
    }
}; // MessageUtil 

var ValidateUtil = {
    checkIsNumber(word){
        if( /[^\d]/.test(word) ){
          return false;
        }
        return true;
    },
    checkIsEmail(word) {
        if ( /.+@.+\..+/.test(word) ) {
          return true;
        }
        return false;
    },
    convertPhoneLocalToGlobal(word) {
        var phoneNum = '';
        if( ! word ) return '';
  
        if( /^(639)/.test(word) ){
          phoneNum = word;
        }
        if( /^(9)/.test(word) ){
          phoneNum = '63'+word;
        }
        if( /^(09)/.test(word) ){
          phoneNum = '63'+word.substr(1, word.length);
        }
        if (phoneNum.length < 12){
          return '';
        }
        return phoneNum;
    },
    convertPhoneGlobalToLocal(word) {
        if( ! word ) return;
        if( /^(639)/.test(word) ){
            return '0'+word.substr(2, word.length); // 09XXXX
        }
        return '';
    },
}; // ValidateUtil

export {
    PageCache,
    RouteUtil,
    SticDateUtil,
    MessageUtil,
    ValidateUtil,
};