<template>
  <div>
    <v-dialog
      persistent
      class="dialog"
      width="550px"
      v-model="dialog"
      :retain-focus="false"
    >
      <v-card
        class="card"
        width="550px"
        max-height="auto"
      >
        <div class="title-wrapper">
          <div class="modal-title">{{ title }}</div>
          <v-btn v-if="close" class="close" text @click="handleClose">Close</v-btn>
        </div>

        <div class="card-content">
          <slot name="content"></slot>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CustomModal",
  props: ["title", "dialog", "handleClose", "close"],
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.card >>> .card-content {
  padding: 1.5rem;
}
.card >>> .title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.5rem;
  border-bottom: 1px solid #d5d5d5;
}
.card >>> .modal-title {
  font-size: 1.7rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: #3b4859;
  margin-top: 15px;
  margin-bottom: 15px;
}
.card >>> .close {
  font-size: 1.3rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6554c0;
  margin-right: 1rem;
}
@media screen and (max-width: 600px) {
  .card >>> .card-content {
    padding: 10px;
  }
  .card >>> .title-wrapper {
    padding: 10px;
  }
  .card >>> .modal-title {
    font-size: 18px;
  }
  .card >>> .close {
    font-size: 14px;
  }
}

.card {
  margin-bottom: 0px;
}
</style>
