/**
 * Kocal/vue-numerals
 * https://github.com/Kocal/vue-numerals/blob/master/src/index.js
 */
import Vue from 'vue';
    
(function(){
    function initNumeral(){
        numeral.locale('en');
    }
    function _installFilter(){
        var mod = {
            install : function(V, options){
                initNumeral();
                var call = function(value, format){ if( !format) format = '0,0'; return numeral(value).format(format); }
                Vue.filter('numeralFormat', call);
                Vue.prototype.$numeralFormat = call;
            }, // end of install

        };
        Vue.use(mod);
    }
    _installFilter();
    
})();
