   <template>
    <div>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <h4 class="card-title"
                    v-bind="attrs"
                    v-on="on">Customer Support</h4>
            </template>
            <span>
                This information will use at invoice email when customer finished to pay.
                <br/>
                So, If customer has a problem, He(She) will contact merchant via this information.
            </span>
        </v-tooltip>
        <template v-if="page==='info'">
            <v-label>Business Name</v-label>
            <v-text-field v-model="businessName" disabled outlined dense></v-text-field>
            
            <v-label>Customer Service Number</v-label>
            <v-text-field v-model="customerPhoneNoReadonly" disabled outlined dense></v-text-field>

            <v-label>Customer Service Email</v-label>
            <v-text-field placeholder="customer@yourshop.com" v-model="customerServiceEmail" disabled outlined dense></v-text-field>

            <v-label>Customer Service Website</v-label>
            <v-text-field placeholder="customer.yourshop.com" v-model="customerServiceWebSiteUrl" disabled outlined dense></v-text-field>

            <v-btn :color="btnSet.modify.color" @click="goEditPage('customer-support')" outlined>Modify</v-btn>
        </template>
        <!-- ---------------------------------------------------------------------------------------------------------------------- -->
        <template v-else-if="page==='modify'">
            <v-form ref="form">
                <v-label>Business Name</v-label>
                <v-text-field
                    v-model="businessName"
                    outlined dense
                    :rules="[formRules.required]"
                ></v-text-field>
                
                <v-label>Customer Service Number</v-label>
                <div class="customer-number-form">
                    <v-text-field
                        class="customer-number-prefix"
                        value="+63"
                        outlined dense
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                    <v-text-field
                        :type="`number`" :inputmode="`numeric`" :pattern="`[0-9]*`" :name="`phone`" placeholder="09xxxxxxxxx (without space or dash)"
                        class="customer-number"
                        v-model="customerPhoneNo"
                        maxlength="13"
                        minlength="13"
                        :rules="[formRules.numberFormat]"
                        outlined dense
                        hide-details="auto"
                    ></v-text-field>
                </div>

                <v-label>Customer Service Email</v-label>
                <v-text-field
                    :type="`email`"
                    :name="`email`"
                    placeholder="info@yourshop.com"
                    v-model="customerServiceEmail"
                    :rules="[formRules.email]"
                    outlined dense>
                </v-text-field>

                <v-label>Customer Service Website</v-label>
                <v-text-field
                    placeholder="customer.yourshop.com"
                    v-model="customerServiceWebSiteUrl"
                    outlined dense>
                </v-text-field>

                <div class="mx-auto pt-3" style="max-width: 600px;">
                    <v-btn :color="btnSet.modify.color" v-if="!canConfirm" @click="onSubmitCustomerSupportInfo()" outlined block>Modify</v-btn>
                </div>
            </v-form>
        </template>
    </div>
</template>

<script>
import {ValidateUtil} from '@/plugins/app-util';

export default {
    props: ['page', 'btnSet', 'goEditPage'],
    components: {
    },
    data() {
        return {
            formRules: {
                required : v => !!v || 'Business Name is required',
                numberFormat : v => !/[^\d]/.test(v) || "Please input a valid phone number.",
                email : v => /.+@.+/.test(v) || 'E-mail must be valid',
            },
            businessName: '',
            customerPhoneNo: '',
            customerServiceEmail: '',
            customerServiceWebSiteUrl: '',
            accountCopyImageId: null,
            accountCopyImagePath: null,
            settingConfirmedStatus: 'D',
            canConfirm: false,
        }
    },
    mounted() {
        this.getCustomerSupportInformation();
    },
    methods: {
        getCustomerSupportInformation() {
            const vue = this;
            const str = APP_APISERVER.uri("get-merchant-customer-support-detail").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));

            APP_SVC.Ajax.request({
                method: 'get',
                url: url,
            }).then(function (res) {
                // Old data has no result for this.
                if( ! res.result ){ res.result = {'sellerInfo' : '{}'}};
                const sellerInfo = JSON.parse(res.result.sellerInfo||'{}');
                vue.businessName = sellerInfo.businessName;
                vue.customerServiceEmail = sellerInfo.customerServiceEmail;
                vue.customerServiceWebSiteUrl = sellerInfo.customerServiceWebSiteUrl || '';

                vue.customerPhoneNo = ValidateUtil.convertPhoneGlobalToLocal(sellerInfo.customerPhoneNo);

            }).catch(function (err) {
                console.error('getCustomerSupportInformation:err', err)
                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        onSubmitCustomerSupportInfo() {
            const vue = this;

            if (!vue.$refs.form.validate()) {
                return;
            }

            const str = APP_APISERVER.uri("get-merchant-customer-support-detail").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));

            APP_SVC.Ajax.request({
                method: 'put',
                url: url,
                data: {
                    businessName : vue.businessName,
                    customerPhoneNo : ValidateUtil.convertPhoneLocalToGlobal(vue.customerPhoneNo),
                    customerServiceEmail : vue.customerServiceEmail,
                    customerServiceWebSiteUrl : vue.customerServiceWebSiteUrl,
                }
            }).then(function () {
                new RouteUtil().goBack();
                    
            }).catch(function (err) {

                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
    },
    computed : {
        customerPhoneNoReadonly(){
            return this.$phoneFormat(this.customerPhoneNo);
        }
    }
}
</script>

<style scoped>
.customer-number-form {
    margin-bottom: 20px;
}
.customer-number-prefix {
    display: inline-block; width: 50px;
}
.customer-number {
    display: inline-block;
}
</style>