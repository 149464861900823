<template>
    <div>
        <h4 class="card-title">Payment</h4>
        <v-alert
            border="left"
            colored-border
            elevation="1"
        >
            These values will apply within 5~10 minutes.
        </v-alert>
        <template v-if="page==='info'">
            <v-simple-table class="mt-5">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Payment</th>
                            <th class="text-left">Payment Type</th>
                            <th class="text-left">Fee</th>
                            <th class="text-left">Policy</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(pgChannel, pI) in selected">
                            <tr v-bind:key="pI">
                                <td>{{pgChannel.pgChannelName}}</td>
                                <td>{{changePgChannelPeriodType(pgChannel.pgChannelPeriodType)}}</td>
                                <td>{{feeFormat(pgChannel)}}</td>
                                <td>{{feePolicyFormat(pgChannel)}}</td>
                            </tr>
                        </template>
                    </tbody>
                </template>
            </v-simple-table>
            <v-btn :color="btnSet.modify.color" v-if="!canConfirm" @click="goEditPage('payment')" outlined>Modify</v-btn>
            <!-- <v-btn :color="btnSet.confirm.color" v-else outlined>Confirm</v-btn>  -->
        </template>
        <template v-else-if="page==='modify'">
            <v-form class="form" ref="form">
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="pgChannelList"
                    :single-select="false"
                    item-key="pgChannel"
                    :items-per-page="20"
                    show-select
                    hide-default-footer
                    mobile-breakpoint="0"
                    @change="onChange(selected)"
                    class="payment-table">
                        <template v-slot:item.feeRate="{ item }">
                            <div is="payment-fee-extra-editor" :item="item" :selected="selected"></div>
                        </template>
                        <template v-slot:item.feePolicy="{ item }">
                            <v-select
                                :items="feePolicyList"
                                item-text="name"
                                item-value="value"
                                v-model="item.feePolicy"
                                outlined
                                dense
                                hide-details
                            ></v-select>
                        </template>
                </v-data-table>
                <div class="mx-auto pt-3" style="max-width: 600px;">
                    <v-btn :color="btnSet.modify.color" v-if="!canConfirm" @click="onSubmitPayment('payment')" outlined block>Modify</v-btn>
                </div>
            </v-form>
        </template>
    </div>
</template>

<script>
import { FeeUtil } from '@/assets/sti/lib/vue/business-util';

import paymentFeeExtraEditor from '@/components/payment-fee-extra-editor.vue';

export default {
    components: {
        'payment-fee-extra-editor' : paymentFeeExtraEditor,
    },
    props: ['page', 'btnSet', 'goEditPage'],
    data() {
        return {
            selected: [],
            pgChannelList: [],
            settingConfirmedStatus: 'D',
            canConfirm: false,
            headers: [
                {
                    text: "Payment",
                    value: "pgChannelName",
                    sortable: false,
                },
                { text: "Fee", value: "feeRate", sortable: false },
                { text: "Policy", value: "feePolicy", sortable: false },
            ],
            feePolicyList : [
                {name : 'Standard', value : 'STANDARD'},
                {name : 'Special', value : 'CUSTOM'}
            ],
        }
    },
    mounted() {
        this.getPayment();
    },
    methods: {
        getPayment() {
            const vue = this;
            const str = APP_APISERVER.uri("get-merchant-payment-channels-detail").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));

            APP_SVC.Ajax.request({
                method: 'get',
                url: url,
            }).then(function (res) {
                const dList = res.result.default;
                const cList = res.result.current;
                vue.pgChannelList = vue.changeValue(dList, cList);
            }).catch(function (err) {
                console.error('pgChannelList:err', err)
                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        changeValue(dList, cList) {
            
            if (cList.length == 0) return dList;

            var vue = this;

            var fnFindInCache = (def)=>{
                var choose;
                _.each(cList, (ca)=>{
                if( ca.pgChannel == def.pgChannel ){
                    choose = ca; 
                    choose.checked = true;
                    return;
                }
                });
                if ( choose == null ) choose = def;
                return choose;
            }

            _.each(dList, (def)=>{
                {// feeExtra ---------------------
                    def.feeExtraValue = (JSON.parse(def.feeExtra||
                        JSON.stringify({
                            "name" : "plus-cash",
                            "rule" : {}
                        })
                    ));
                }
                if( ! def.feePolicy ) def.feePolicy = 'STANDARD'; // Default is empty means standard.

                // Upper side is default section
                // ------------------------------------------------------------- 
                // Below side is cache section
                var cache = fnFindInCache(def);
                if( ! cache ) return;

                // Decimal to Percent [0,0.0]
                def.feeRatePercent = vue.$numeralFormat(Number(cache.feeRate)*100, '0,0.[00]');
                def.feeRate = cache.feeRate;
                def.feePolicy = cache.feePolicy || 'STANDARD';

                {// feeExtra ---------------------
                    // new features(from default) add into exist cache
                    if( def.feeExtra && !cache.feeExtra ) {
                        cache.feeExtra = def.feeExtra;
                    }
                    def.feeExtra = (cache.feeExtra || "{}");
                    def.feeExtraValue = (JSON.parse(def.feeExtra||
                        JSON.stringify({
                            "name" : "plus-cash",
                            "rule" : {}
                        })
                    ));
                }

                def.valid = true; // initial values
                if( cache.checked ){
                    vue.selected.push(def);
                }
            });
            
            return dList;
        },
        onSubmitPayment() {
            const vue = this;
            // Fee values verification
            {
                var isValidAll = true, errorItem = '';
                _.each(vue.selected, function(v){
                    // NOT feePolicy: "STANDARD"
                    if( v.feePolicy === 'STANDARD' ) return;
                    if( ! v.valid ){ isValidAll = false; errorItem = v; }
                });

                if( ! isValidAll ){
                    Swal.fire({
                        title: 'Wrong values are set',
                        text: "Please check values again before submit this. Payment: " + errorItem.pgChannelName,
                        type: 'warning',
                    }).then(() => {
                    });
                    return;
                }
            }
            // End of Fee values verification

            Swal.fire({
                title: 'Are you sure to apply?',
                text: "Please check again before do this.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, apply it!'
            }).then((result) => {
                if (! (result.value) ){
                    return;
                }
                vue.onSubmitPaymentApi.apply(vue);
            });
        },
        onSubmitPaymentApi() {
            const vue = this;
            const str = APP_APISERVER.uri("get-merchant-payment-channels-detail").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));

            var sendSeleced = [];
            _.each(vue.selected, function(v){
                sendSeleced.push(v);
            })

            APP_SVC.Ajax.request({
                method: 'put',
                url: url,
                data: sendSeleced
            }).then(function (res) {
                new RouteUtil().goBack();
                    
            }).catch(function (err) {
                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        feeFormat(pgChannel){
            return FeeUtil.feeExtraConvert(this, pgChannel.feeRate, pgChannel.feeExtra);
        },
        feePolicyFormat(pgChannel){
            if( pgChannel.feePolicy == 'STANDARD' ) return 'Standard';
            else return 'Special';
        },
        changePgChannelPeriodType(periodType) {
            if(periodType=="LOAD") return 'Load';
            if(periodType=="LC_WALLET") return 'Wallet+Bank';
            if(periodType=="BANK") return 'Bank';
        },
    }
}
</script>

<style scoped>
</style>