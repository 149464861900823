<template>
    <div>
        <h4 class="card-title">Payout Period</h4>
        <v-alert
            border="left"
            colored-border
            elevation="1"
        >
            These work when above Payout is on.<br>
            And these values will apply within 5~10 minutes.
        </v-alert>
        <template v-if="page === 'info'">
            <v-simple-table class="mt-5">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center">Payment Type</th>
                            <th class="text-center">Payout Period</th>
                            <th class="text-center">Payout Period Type</th>
                            <th class="text-center">Cut Off</th>
                            <th class="text-center">Banking Days</th>
                            <th class="text-center">Effective Date</th>
                            <th class="text-center">Default</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="b2bPayoutPeriodList.length===0">
                            <tr><td colspan="7" class="text-center text-secondary">No data available</td></tr>
                        </template>
                        <template v-for="(rule, idx) in b2bPayoutPeriodList">
                            <tr class="text-center" v-bind:key="idx">
                                <td>{{ changePeriodTypeName(rule.pgChannelPeriodType) }}</td>
                                <td>
                                    {{ changePeriodUnit(rule.periodSort)}}
                                </td>
                                <td>{{ rule.periodRuleName }}</td>
                                <td>{{ changeCutOffDay(rule.periodSort, JSON.parse(rule.cutOffRule))}}</td>
                                <td>{{ `+ `+JSON.parse(rule.payoutDayRule ).afterDays+` days` }}</td>
                                <td>{{ rule.effectiveDate }}</td>
                                <td v-bind:class="{'text-danger':rule.isCustom==='Default'}">{{ rule.isCustom }}</td>
                            </tr>
                        </template>
                    </tbody>
                </template>
            </v-simple-table>
            <v-btn
                :color="btnSet.modify.color"
                @click="goEditPage('payout-period')"
                outlined
                >Modify</v-btn
            >
            <!-- <v-btn :color="btnSet.confirm.color" v-else outlined>Confirm</v-btn>  -->
        </template>
        <template v-else-if="page === 'modify'">
            <div class="dataTables_wrapper">
                <v-row>
                    <v-col cols="12" md="4" class="pb-10">
                        <v-select
                            :items="paymentOutTypeList"
                            v-model="selectedType"
                            label="Payment Types"
                            item-text="pgChannelPeriodName"
                            item-value="pgChannelPeriodType"
                            @change="changePaymentType"
                            outlined
                            dense
                            hide-details
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-label>System</v-label>
                </v-row>
                <v-row>
                    <v-col class="pb-10">
                        <v-data-table
                            v-model="selectedRule"
                            :headers="headers"
                            :items="defaultRule"
                            :hide-default-footer="true"
                            :mobile-breakpoint="0"
                            :items-per-page="100"
                            item-key="periodRuleSeq"
                            single-select
                            show-select
                        >
                            <template v-slot:item.periodSort="{ item }">
                                {{ changePeriodUnit(item.periodSort) }}
                            </template>
                            <template v-slot:item.periodRuleName="{ item }">
                                {{ item.periodRuleName }}
                            </template>                            
                            <template v-slot:item.cutOffRule="{ item }">
                                {{ changeCutOffDay(item.periodSort, JSON.parse(item.cutOffRule)) }}
                            </template>
                            <template v-slot:item.payoutDayRule="{ item }">
                                {{ `+ `+JSON.parse(item.payoutDayRule).afterDays+` days` }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-label>Custom</v-label>
                </v-row>
                <v-row>
                    <v-col class="pb-10">
                        <v-data-table
                            v-model="selectedRule"
                            :headers="headers"
                            :items="customRules"
                            :hide-default-footer="true"
                            :mobile-breakpoint="0"
                            :items-per-page="100"
                            item-key="periodRuleSeq"
                            single-select
                            show-select
                        >
                            <template v-slot:item.periodSort="{ item }">
                                {{ changePeriodUnit(item.periodSort) }}
                            </template>
                            <template v-slot:item.pgChannelPeriodType="{ item }">
                                {{ changePeriodTypeName(item.pgChannelPeriodType) }}
                            </template>                            
                            <template v-slot:item.cutOffRule="{ item }">
                                {{ changeCutOffDay(item.periodSort, JSON.parse(item.cutOffRule)) }}
                            </template>
                            <template v-slot:item.payoutDayRule="{ item }">
                                {{ `+ `+JSON.parse(item.payoutDayRule).afterDays+` days` }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="mx-auto pt-3" style="max-width: 600px;">
                            <v-btn :color="btnSet.modify.color" @click="onSubmitPayoutCustom" outlined block>Modify</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </template>
    </div>
</template>

<script>
import { FeeUtil } from "@/assets/sti/lib/vue/business-util";

export default {
    components: {
    },
    props: ["page", "btnSet", "goEditPage"],
    data() {
        return {
            b2bPayoutPeriodList: [],

            paymentOutTypeList: [], // { pgChannelPeriodType: "LOAD", pgChannelPeriodName:"Load" }
            selectedType: "NONE",

            headers: [
                { text: "Payout Period", value: "periodSort", align: "center", sortable: false },
                { text: "Payout Period Type", value: "periodRuleName", align: "center", sortable: false },
                { text: "Cut off", value: "cutOffRule", align: "center", sortable: false},
                { text: "Banking Days", value: "payoutDayRule", align: "center", sortable: false},
                { text: "Default", value: "pgChannelPeriodDefault", align: "center", sortable: false},
            ],
            defaultRule: [],
            customRules: [],
            selectedRule: [],
            
            b2bPeriods : [],
            periodRules : [],
        };
    },
    mounted() {
        this.getPayoutPeriodLists();
    },
    methods: {
        getPayoutPeriodLists() {
            const vue = this;
            const str = APP_APISERVER.uri("get-merchant-payout-period-detail").asString();
            const url = str.replace(
                "{company_seq}",
                new RouteUtil().current.param("userSeq")
            );

            APP_SVC.Ajax.request({
                method: "get",
                url: url,
            })
                .then(function(res) {
                    const b2bPeriods = res.result.b2bPeriods || [];
                    const periodRules = res.result.periodRules.filter(x=> x.pgChannelPeriodType !== 'BANK') || [];
                    const b2bPeriodSchedule = res.result.b2bPeriodSchedule || [];
                    const b2bPeriodType = res.result.b2bPeriodType || [];

                    vue.b2bPeriods = b2bPeriods;
                    vue.periodRules = periodRules;

                    vue.makeB2bPayoutPeriodList(b2bPeriodSchedule, b2bPeriodType, b2bPeriods, periodRules);
                    vue.paymentOutTypeList = b2bPeriodType;
                })
                .catch(function(err) {
                    console.error("PayoutPeriodList:err", err);
                    Swal.fire({
                        title:
                            (err.data && err.data.result_message) ||
                            APP_SVC.Ajax.errorMessageDefault(),
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonClass: "btn-info",
                        confirmButtonText: "OK",
                    });
                });
        },
        makeB2bPayoutPeriodList(b2bPeriodSchedule, b2bPeriodType, b2bPeriods, periodRules){
            const b2bPayoutPeriodList = [];
            //push b2b period Rules
            b2bPeriodType.forEach(type =>{
                //check custom
                const custom = b2bPeriods.find(c=>c.pgChannelPeriodType === type.pgChannelPeriodType);
                const scheduled = b2bPeriodSchedule.find(s=>s.pgChannelPeriodType === type.pgChannelPeriodType);
                if(custom){
                    const rule = periodRules.find(r=> r.periodRuleSeq === custom.periodRuleSeq);
                    b2bPayoutPeriodList.push({
                        ...rule,
                        isCustom : 'Custom',
                        effectiveDate : scheduled ? scheduled.nextEffectiveDate : '-'
                    })
                }else{
                    const defaultRule = periodRules.find(r=>{
                        return r.pgChannelPeriodType === type.pgChannelPeriodType
                                && r.pgChannelPeriodDefault === 'Y'
                    });
                    b2bPayoutPeriodList.push({
                        ...defaultRule,
                        isCustom : 'Default',
                        effectiveDate : scheduled ? scheduled.nextEffectiveDate : '-'
                    })
                }
            })
            //end b2b period Rules
            this.b2bPayoutPeriodList = b2bPayoutPeriodList;
        },
        changePaymentType(){
            const vue = this;      
            const customRules = [];
            const defaultRule = [];
            const selectedRule = [];
            vue.periodRules
                .filter(rule=>rule.pgChannelPeriodType===vue.selectedType)
                .forEach(rule=>{
                    customRules.push(rule);

                    if(rule.pgChannelPeriodDefault == "Y"){
                        const copiedRule = {...rule, periodRuleSeq: "DEFAULT"}
                        defaultRule.push(copiedRule);
                        selectedRule.length == 0 && selectedRule.push(copiedRule);
                    }

                    const isB2bCustom = vue.b2bPeriods.find(c=> 
                        c.pgChannelPeriodType === vue.selectedType
                        && c.periodRuleSeq === rule.periodRuleSeq);
                    if(isB2bCustom) selectedRule[0] = rule;
                })
            
            vue.customRules = customRules;
            vue.defaultRule = defaultRule;
            vue.selectedRule = selectedRule;
        },
        onSubmitPayoutCustom() {
            const vue = this;

            if(vue.selectedRule.length===0){
                Swal.fire({
                    title: 'Please select at least one.',
                    type: 'warning',
                }).then(() => {
                });
                    return;
            }

            Swal.fire({
                title: 'Are you sure to apply?',
                text: "Please check again before do this.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, apply it!'
            }).then((result) => {
                if (! (result.value) ){
                    return;
                }
                const companySeq = new RouteUtil().current.param("userSeq");
                const selectedRuleSeq = vue.selectedRule[0].periodRuleSeq;
                const periodType = vue.selectedType;
                if(selectedRuleSeq === "DEFAULT"){
                    vue.deletePayoutCustom(companySeq, periodType);
                }else{
                    vue.updatePayoutCustom(companySeq, selectedRuleSeq);
                }
                return;
            });
        },
        updatePayoutCustom(companySeq, periodRuleSeq) {
            
            const vue = this;
            const str = APP_APISERVER.uri("put-merchant-payout-period-detail").asString();
            const url = str.replace("{company_seq}", companySeq)
                            .replace("{payout_period_rule}", periodRuleSeq);

            APP_SVC.Ajax.request({
                method: 'put',
                url: url
            }).then(function (res) {
                new RouteUtil().goBack();
            }).catch(function (err) {
                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        deletePayoutCustom(companySeq, payoutPeriodType){

            const vue = this;
            const str = APP_APISERVER.uri("delete-merchant-payout-period-detail").asString();
            const url = str.replace("{company_seq}", companySeq)
                            .replace("{payout_period_type}", payoutPeriodType);

            APP_SVC.Ajax.request({
                method: 'delete',
                url: url
            }).then(function (res) {
                new RouteUtil().goBack();
            }).catch(function (err) {
                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        changePeriodTypeName(periodType) {
            if (periodType == "LOAD") return "Load";
            if (periodType == "LC_WALLET") return "Wallet+Bank";
            if (periodType == "BANK") return "Bank";
        },
        changePeriodUnit(unit) {
            if(unit=="MONTHLY") return 'Monthly';
            if(unit=="WEEKLY") return 'Weekly';
            if(unit=="WEEKS2") return '2 Weeks';
            if(unit=="DAILY") return 'Daily';
        },
        changeCutOffDay(unit, cutOff) {
            const cutOffRuleSet = {
                MONTHLY : "startDate",
                WEEKLY : "startDayOfWeek",
                WEEKS2 : "startDayOfWeek",
                DAILY : "days"
            };
            const weeklySet = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            const id = cutOffRuleSet[unit];
            const cutOffNum = cutOff[id]-1;
            if(unit=="MONTHLY") return ( cutOffNum == 0 ) ? "Last Day" : cutOffNum;
            if(unit=="WEEKLY" || unit=="WEEKS2") return ( cutOffNum == 0 ) ? "Sun" : weeklySet[cutOffNum-1];
            if(unit=="DAILY") return "-";
        },
    },
};
</script>

<style scoped></style>
