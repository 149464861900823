<template>
    <li v-if="type == 'navigation-header'" v-bind:class="type">
        <span></span>
    </li>
    <li v-else v-bind:class="{'type':true, 'has-sub':subs.length>0,'active':active}">
        <a :href="link">
            <i class="feather" v-bind:class="'icon-'+icon"></i>
            <span class="menu-title" :data-i18n="name" v-text="name"></span>
        </a>
        <ul class="menu-content" v-if="subs.length > 0">
            <template v-for="(sub, index) in subs">
                <li v-bind:key="index">
                    <a :href="sub.link"><i class="feather icon-circle"></i>
                        <span class="menu-item" :data-i18n="sub.name">{{sub.name}}</span>
                    </a>
                </li>
                
            </template>
        </ul>
    </li>
</template>
<script>
export default {
    props: ['type', 'name', 'icon', 'link', 'subs', 'active'],
    data: function(){
        return {
        }
    },
    mounted : function(){
    },
    methods : {
        
    }
}
</script>
<style scoped>

</style>