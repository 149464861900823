
// [ FEE UTIL ] BEGIN ------------------------------------------------
const FeeUtil = {
    // Default Fee formatter
    // Fee : 5.6% + ₱10 or 5.64% (₱10 min)
    // Parameter : feeRate(Decimal), extraJs(String of json format)
    feeExtraConvert(vue, feeRate, extraJs){
        const jExtra = JSON.parse(extraJs||'{}');
        switch( jExtra.name ){
          case "plus-cash" : {
            var plusCash = Number(jExtra.rule["plus-cash"] || 0.00);
            var addExtra = plusCash ? ' + ₱' + vue.$numeralFormat(plusCash, '0,0.[00]') : '';
            return vue.$numeralFormat(feeRate, '0,0.[00]%') + addExtra;
          }
          case "at-least" : {
            var minCash = Number(jExtra.rule["min-cash"] || 0.00);
            var addExtraAtLeast = minCash ? ' (₱' + vue.$numeralFormat(minCash, '0,0.[00]') + ' min)' : '';
            return vue.$numeralFormat(feeRate, '0,0.[00]%') + addExtraAtLeast;
          }
          default : 
            return vue.$numeralFormat(feeRate, '0,0.[00] %');
        }
    },
    feeExtraModels(){
      return [
        {
            "leg" : "Plus", "val" : "plus-cash",
        },
        {
            "leg" : "At Least", "val" : "at-least",
        },
      ];
    },
    feeRateReValidator : function(vue, item){
      // feeRate(Percent with String) to Decimal
      // "1.234" -> 1.23 -> 0.0123
      item.feeRatePercent = (Math.round(Number(item.feeRatePercent)*100)/100);
      item.feeRate = item.feeRatePercent/100;
      
      item.feeExtraValue.toPrintPdf = FeeUtil.feeExtraConvert(vue, item.feeRate, JSON.stringify(item.feeExtraValue));
      item.feeExtra = JSON.stringify(item.feeExtraValue);
    }
};
// [ FEE UTIL ] END   ------------------------------------------------

export {
    FeeUtil,
};