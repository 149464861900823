<template>
    <div>
        <h4 class="card-title">Account Detail</h4>
        <template v-if="page==='info'">
            <v-label>Email(ID)</v-label>
            <v-text-field placeholder="Email(ID)" v-model="userId" dense disabled outlined></v-text-field>

            <v-label>Name</v-label>
            <v-text-field placeholder="Name" v-model="userName" dense disabled outlined></v-text-field>

            <v-label>Contact Number</v-label>
            <v-text-field placeholder="Contact Number" v-model="contactPhoneNoReadOnly" dense disabled outlined></v-text-field>
            <v-btn :color="btnSet.modify.color" @click="goEditPage('account')" outlined>Modify</v-btn>
        </template>
        <template v-else-if="page==='modify'">
            <v-label>Email(ID)</v-label>
            <v-text-field 
                :type="`email`"
                placeholder="Email(ID)" v-model="userId" dense outlined></v-text-field>

            <v-label>Name</v-label>
            <v-text-field 
                :type="`text`"
                :name="`userName`"
                placeholder="Name" v-model="userName" dense outlined></v-text-field>

            <v-label>Contact Number</v-label>
            <v-text-field 
                :type="`number`" :inputmode="`numeric`" :pattern="`[0-9]*`" :name="`phone`" placeholder="09xxxxxxxxx (without space or dash)"
                v-model="contactPhoneNo" dense outlined></v-text-field>
            <div class="mx-auto pt-3" style="max-width: 600px;">
                <v-btn :color="btnSet.modify.color" @click="onSubmitAccountDetails()" outlined block>Modify</v-btn>
            </div>
        </template>
    </div>
</template>

<script>
import {ValidateUtil} from '@/plugins/app-util';

export default {
    props: ['page', 'btnSet', 'goEditPage'],
    data() {
        return {
            b2bUserSeq: '',
            userId: '',
            userName: '',
            contactPhoneNo: '',
        }
    },
    created() {
    },
    mounted() {
        this.getAccountDetails();
    },
    methods: {
        getAccountDetails(){
            const vue = this;
            const str = APP_APISERVER.uri("get-merchant-account-detail").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));

            APP_SVC.Ajax.request({
                method: 'get',
                url: url,
            }).then(function (res) {
                const rt = res.result[0];
                vue.b2bUserSeq = rt.b2bUserSeq;
                vue.userId = rt.userId;
                vue.userName = rt.userName;
                vue.contactPhoneNo = ValidateUtil.convertPhoneGlobalToLocal(rt.contactPhoneNo);
                
            }).catch(function (err) {
                console.error('getAccountDetails:err', err)
                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        onSubmitAccountDetails(){
            const vue = this;
            const str = APP_APISERVER.uri("get-merchant-account-detail").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq')) + "/" + vue.b2bUserSeq;

            APP_SVC.Ajax.request({
                method: 'put',
                url: url,
                data: {
                    userId : vue.userId,
                    userName : vue.userName,
                    contactPhoneNo : ValidateUtil.convertPhoneLocalToGlobal(vue.contactPhoneNo),
                }
            }).then(function () {
                new RouteUtil().goBack();
                    
            }).catch(function (err) {

                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
    },
    computed : {
        contactPhoneNoReadOnly(){
            return this.$phoneFormat(this.contactPhoneNo);
        }
    }
}
</script>

<style>

</style>