<template>
    <!-- BEGIN: Footer-->
    <footer class="footer footer-static footer-light">
        <p class="clearfix lighten-2 mb-0 text-center">
            <span class="d-block d-md-inline-block mt-25 ">
                COPYRIGHT &copy;
                <a class="text-bold-800" href="https://www.anyonepay.ph" target="_blank">AnyonePay,</a>
                All rights Reserved on AnyonePay dashboard 
            </span>
            <button class="btn btn-primary btn-icon scroll-top" type="button"><i class="feather icon-arrow-up"></i></button>
        </p>
    </footer>
</template>
<script>
export default {
    data: function () {
        return {
            count: 0
        }
    },
}
</script>
<style scoped>

</style>