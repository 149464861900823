<template>
    <div class="card">
        <div class="card-body p-1">
            <v-tabs
                v-model="cTab"
                class="rounded-lg w-100"
                show-arrows
                >
                <v-tab @click="movePage(tabList[0].url)">{{tabList[0].link}}<status-icon :stepStatus="step1"></status-icon></v-tab>
                <i class="fas fa-chevron-right" aria-hidden="true"></i>
                <v-tab @click="movePage(tabList[1].url)">{{tabList[1].link}}<status-icon :stepStatus="step2"></status-icon></v-tab>
                <i class="fas fa-chevron-right" aria-hidden="true"></i>
                <v-tab @click="movePage(tabList[2].url)">{{tabList[2].link}}<status-icon :stepStatus="step3"></status-icon></v-tab>
            </v-tabs>
        </div>
    </div>
</template>

<script>
import statusIcon from "@/components/status-icon.vue";

export default {
    props: ['tabList', 'tab', 'param', 'step1', 'step2', 'step3'],
    components: {
        statusIcon
    },
    data: function(){
        return {
            cTab: '',
        }
    },
    mounted() {
        this.cTab = this.tab;
    },
    methods: {
        movePage(url) {
            if (!this.param) return (new RouteUtil(url)).go();
            
            const pm = {};
            pm[this.param] = new RouteUtil().current.param(this.param);
            return (new RouteUtil(url)).param(pm).go();
        },
    }
}
</script>

<style scoped>
    .i, .fas, .fa {
        display: flex;
        align-items: center;
    }
    .fas.fa-chevron-right {
        padding: 0 16px;
        color: grey;
    }
</style>