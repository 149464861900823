import { render, staticRenderFns } from "./main-menu-nav-item.vue?vue&type=template&id=29e6dd2a&scoped=true&"
import script from "./main-menu-nav-item.vue?vue&type=script&lang=js&"
export * from "./main-menu-nav-item.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29e6dd2a",
  null
  
)

export default component.exports