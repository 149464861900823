<template>
    <table>
        <tbody>
            <tr v-if="item.feeExtraValue">
                <td>
                    <v-select :items="feeExtraModels" 
                        item-text="leg" item-value="val" 
                        label="Fee Model"
                        @change="changedFeeModel(item)"
                        v-model="item.feeExtraValue.name" dense outlined hide-details
                        :disabled="isDisabled(item)">
                    </v-select>
                </td>
                <td>
                    <table v-if="item.feeExtraValue.name == 'plus-cash'">
                        <tbody>
                            <tr class="feeModel">
                                <td>
                                    <v-text-field
                                        class="righted-input"
                                        type="number" 
                                        v-model="item.feeRatePercent"
                                        suffix="%"
                                        :disabled="isDisabled(item)"
                                        @change="changedItemValues(item)"
                                        >
                                    </v-text-field>
                                </td>
                                <td>
                                    +
                                </td>
                                <td>
                                    <v-text-field
                                        class="righted-input"
                                        type="number" 
                                        v-model="item.feeExtraValue.rule['plus-cash']"
                                        prefix="₱"
                                        :disabled="isDisabled(item)"
                                        @change="changedItemValues(item)"
                                        >
                                    </v-text-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-if="item.feeExtraValue.name == 'at-least'">
                        <tbody>
                            <tr class="feeModel">
                                <td>
                                    <v-text-field
                                        class="righted-input"
                                        type="number" 
                                        v-model="item.feeRatePercent"
                                        suffix="%"
                                        :disabled="isDisabled(item)"
                                        @change="changedItemValues(item)"
                                        >
                                    </v-text-field>
                                </td>
                                <td>
                                    (
                                </td>
                                <td>
                                    <v-text-field
                                        class="righted-input"
                                        type="number" 
                                        v-model="item.feeExtraValue.rule['min-cash']"
                                        prefix="₱"
                                        :disabled="isDisabled(item)"
                                        @change="changedItemValues(item)"
                                        >
                                    </v-text-field>
                                </td>
                                <td>
                                    <div style="min-width: 4em;">min )</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { FeeUtil } from '@/assets/sti/lib/vue/business-util';

export default {
    props: {
        item: Object,
        selected: Array,
    },
    data: function () {
        return {
            feeExtraModels : FeeUtil.feeExtraModels(),  /* Array */
        }
    },
    methods : {
        isDisabled(item){
            if( ! this.selected ) return false; // disable selected mode
            if( ! this.selected.includes(item) ) return true;
            if( item.feePolicy=='STANDARD' ) return true;
            return false;
        },
        changedFeeModel(item){
            const ext = item.feeExtraValue;
            switch(ext.name){
                case 'plus-cash' : {
                    if (ext.rule['min-cash']) {
                        ext.rule['plus-cash'] = ext.rule['min-cash'];
                        delete ext.rule['min-cash'];
                    }
                    FeeUtil.feeRateReValidator(this, item);
                    return;
                }
                case 'at-least' : {
                    if (ext.rule['plus-cash']) {
                        ext.rule['min-cash'] = ext.rule['plus-cash'];
                       delete ext.rule['plus-cash'];
                    }
                    FeeUtil.feeRateReValidator(this, item);
                    return;
                }
            }
        },
        changedItemValues(item){
            // Changed then valid status set.
            //
            item.valid = false;
            (()=>{
                if(!item.feeExtraValue){
                    item.valid = false;
                    return;
                }
                const ext = item.feeExtraValue;
                switch(ext.name){
                    case 'plus-cash' : {
                        if( typeof item.feeRatePercent === 'undefined' || isNaN(item.feeRatePercent) || String(item.feeRatePercent).trim().length == 0 ){
                            item.valid = false;
                            return;
                        }
                        if( Number(item.feeRatePercent) < 0 ){
                            item.valid = false;
                            return;
                        }
                        if( Number(item.feeRatePercent) > 100 ){
                            item.valid = false;
                            return;
                        }
                        if( ! ext.rule ){
                            item.valid = false;
                            return;
                        }
                        if( typeof ext.rule['plus-cash'] === 'undefined' || isNaN(ext.rule['plus-cash']) || String(ext.rule['plus-cash']).trim().length == 0 ){
                            item.valid = false;
                            return;
                        }
                        if( Number(ext.rule['plus-cash']) < 0 ){
                            item.valid = false;
                            return;
                        }
                        item.valid = true;
                        return;
                    }
                    case 'at-least' : {
                        if( typeof item.feeRatePercent === 'undefined' || isNaN(item.feeRatePercent) || String(item.feeRatePercent).trim().length == 0 ){
                            item.valid = false;
                            return;
                        }
                        if( Number(item.feeRatePercent) < 0 ){
                            item.valid = false;
                            return;
                        }
                        if( Number(item.feeRatePercent) > 100 ){
                            item.valid = false;
                            return;
                        }
                        if( ! ext.rule ){
                            item.valid = false;
                            return;
                        }
                        if( typeof ext.rule['min-cash'] === 'undefined' || isNaN(ext.rule['min-cash']) || String(ext.rule['min-cash']).trim().length == 0 ){
                            item.valid = false;
                            return;
                        }
                        if( Number(ext.rule['min-cash']) < 0 ){
                            item.valid = false;
                            return;
                        }
                        item.valid = true;
                        return;
                    }
                    default : {
                        item.valid = false;
                        return;
                    }
                }
            })();

            if( ! item.valid ) return;

            FeeUtil.feeRateReValidator(this, item);
        }
    },
}
</script>
<style scoped>
    .feeModel td {
        padding-left: 5px;
        padding-right: 5px;
    }
    .righted-input >>> input {
        text-align: right;
    }
</style>