<template>
    <div class="card">
        <div class="card-body p-1">
            <v-tabs
                v-model="cTab"
                class="rounded-lg w-100"
                show-arrows
                >
                <v-tab
                    v-for="item in tabList"
                    :key="item.tab" @click="movePage(item.url)">
                    {{item.link}} {{item.count}}
                </v-tab>
            </v-tabs>
        </div>
    </div>
</template>

<script>
export default {
    props: ['tabList', 'tab', 'param'],
    data: function(){
        return {
            cTab: '',
        }
    },
    mounted() {
        this.cTab = this.tab;
    },
    methods: {
        movePage(url) {
            if (!this.param) return (new RouteUtil(url)).go();
            
            const pm = {};
            pm[this.param] = new RouteUtil().current.param(this.param);
            return (new RouteUtil(url)).param(pm).go();
        },
    }
}
</script>

<style scoped>

</style>