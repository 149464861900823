<template>
    <div>
        <v-row align="center" v-if="stepStatus=='W'">
            <v-col class="d-flex" cols="12" md="6">
                <v-btn @click="beforeApproveInternal()" class="mr-1 mb-1 w-100" color="primary">Approve</v-btn>
            </v-col>
            <v-col class="d-flex" cols="12" md="6">
                <v-btn class="mr-1 mb-1 w-100" color="error" @click="openDialog()">Reject</v-btn>
            </v-col>
        </v-row>
        <v-row align="center" v-if="location=='activate' && (stepStatus=='R' || stepStatus=='C')">
            <v-col class="d-flex" cols="12" md="6">
                <v-btn class="mr-1 mb-1 w-100" @click="onCancel()">Cancel</v-btn>
            </v-col>
        </v-row>
        <custom-modal title="Reason for rejection" :dialog="rejectDialog" :close="true" :handleClose="handleClose"> 
            <template v-slot:content>
                <v-form class="form" ref="form">
                    <v-textarea
                        rows="5"
                        v-model="rejectReason"
                        outlined
                        :rules="[formRules.required]"
                        >
                    </v-textarea>
                    <v-btn class="w-100" color="error" @click="onReject()">Send</v-btn>
                </v-form>
            </template>
        </custom-modal>
    </div>
</template>

<script>
import customModal from "@/components/custom-modal.vue";
import { FeeUtil } from '@/assets/sti/lib/vue/business-util';

export default {
    props: ['paramName', 'paramValue', 'stepStatus', 'location', 'selected', 
        'beforeApprove',
        'afterApprove', 'afterReject', 'afterCancel'],
    components: {
        customModal,
    },
    data: function() {
        return {
            rejectDialog: false,
            rejectReason: '',
            formRules: {
                required: v => !!v || 'Please enter text.',
            },
        }
    },
    methods: {
        openDialog() {
            this.rejectDialog = true;
        },
        handleClose() {
            this.rejectDialog = false;
        },
        beforeApproveInternal() {
            const vue = this;
            (async()=>{
                if( typeof (vue.beforeApprove) == "function"  ){
                    await vue.beforeApprove.call(vue);
                }

                vue.onApprove(vue.paramValue);
                
                if( typeof (vue.afterApprove) == "function"  ){
                    await vue.afterApprove.call(vue);
                }
            })();
        },
        onApprove(stepNo){
            const vue = this;
            
            const uriKey = stepNo < 3 ? "get-activate-status" : 'get-activate-'+stepNo+'-step-detail';

            const str = vue.location == 'activate' ? APP_APISERVER.uri(uriKey).asString() : APP_APISERVER.uri("put-merchant-status").asString()
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));
            const sendData = {};
            sendData[vue.paramName] = stepNo;
            sendData['action'] = 'confirm';

            APP_SVC.Ajax.request({
                method: 'put',
                url: url,
                data: sendData
            }).then(function (res) {
                vue.$emit(vue.location + stepNo, res.result);

                // Callback If handler registered
                if( typeof (vue.afterApprove) == "function"  ){
                    vue.afterApprove.call(vue);
                }
                
            }).catch(function (err) {

                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        onReject(){
            const vue = this;
            if (!vue.$refs.form.validate()) return;

            const str = vue.location == 'activate' ? APP_APISERVER.uri("get-activate-status").asString() : APP_APISERVER.uri("put-merchant-status").asString()
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));
            const sendData = {};
            sendData[vue.paramName] = vue.paramValue;
            sendData['action'] = 'reject';
            sendData['reason'] = vue.rejectReason;

            APP_SVC.Ajax.request({
                method: 'put',
                url: url,
                data: sendData
            }).then(function (res) {
                vue.$emit(vue.location + vue.paramValue, res.result);
                vue.rejectReason = '';
                vue.handleClose();

                // Callback If handler registered
                if( typeof (vue.afterReject) == "function"  ){
                    vue.afterReject.call(vue);
                }
                    
            }).catch(function (err) {

                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        onCancel() {
            const vue = this;
            const str = APP_APISERVER.uri("get-activate-status").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));
            const sendData = {};
            sendData[vue.paramName] = vue.paramValue;
            sendData['action'] = 'cancel';

            APP_SVC.Ajax.request({
                method: 'put',
                url: url,
                data: sendData
            }).then(function (res) {
                vue.$emit(vue.location + vue.paramValue, res.result);
                
                // Callback If handler registered
                if( typeof (vue.afterCancel) == "function"  ){
                    vue.afterCancel.call(vue);
                }

            }).catch(function (err) {

                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
    }
}
</script>

<style>

</style>