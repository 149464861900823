<template>
  <div class="wrapper">
    <table class="table">
      <tbody>
        <tr>
          <th class="text-left">Transaction ID</th>
          <td class="text-left">{{ transactionId }}</td>
        </tr>
        <tr>
          <th class="text-left">Trans Date</th>
          <td class="text-left">{{ transactionDate | datetimeFormat('tableDate+time') }}</td>
        </tr>
        <tr>
          <th class="text-left">Description</th>
          <td class="text-left description">
            <div class="main-desc">{{ product }}</div>
          </td>
        </tr>
        <tr>
          <th class="text-left">Payment</th>
          <td class="text-left">
            <div>{{ pgChannel }}</div>
          </td>
        </tr>
        <tr>
          <th class="text-left">Gross</th>
          <td class="text-left">PHP {{ totalAmount | numeralFormat('0,0.[00]') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "PaymentDetailModalContent",
  props: ["transactionId", "transactionDate", "product", "pgChannel", "totalAmount"],
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.wrapper {
  overflow-x: auto;
}
.table {
  width: 100%;
  border-top: 1px solid #eeeef0;
  border-bottom: 1px solid #eeeef0;
  border-collapse: collapse;
}
.table tr th {
  background-color: #f7f7f8;
  border-top: 1px solid #eeeef0 !important;
  border-bottom: 1px solid #eeeef0 !important;
  font-size: 1.1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #3b4859;
  max-width: 142px;
  padding-left: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.table tr td {
  border-top: 1px solid #eeeef0 !important;
  border-bottom: 1px solid #eeeef0 !important;
  font-size: 1.1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3b4859;
  padding-left: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.description {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.description .main-desc {
  font-size: 1.1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3b4859;
}
.description .sub-desc {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8c8c8c;
}
</style>
