/**
 * Role : Replace DB Constant code to human string redable
 * 
 * - How to use
 * -- In Vue instance as filter
 * {{ value | dbTrans 'DBColumn' }}
 * 
 */
import Vue from 'vue'
import * as _ from 'lodash'

// fnGetCodeOne : exists in common-api.js (Loaded by JSON-DATA.js)
function _installFilter(){
    var stiDbTrans = {
        install : function(V){
            var transFn = function(enumVal, key){
                // console.log('fnGetCodeOne', fnGetCodeOne, enumVal, key);
                if( typeof fnGetCodeOne === 'undefined' ){
                    console.error('DBTrans> fnGetCodeOne function not defined. please check common-api.js');
                    return;
                } 
                var transVal = window.fnGetCodeOne(key, enumVal);
                if( ! transVal ){
                    console.error('DBTrans> fnGetCodeOne:Value Not Exist:',key, enumVal);
                }
                return transVal;
            };
            V.filter('dbTrans', transFn);
            Vue.prototype.$dbTrans = transFn;
            // -----------------------------------------------------------------
            // "codeGrpId":"OPTIONS_HELP_CENTER_CATE" -> optionName is HELP_CENTER_CATE
            //
            var optionsFn = function(optionName){
                if( typeof fnGetCodeList === 'undefined' ){
                    console.error('DBOptions> fnGetCodeList function not defined. please check common-api.js');
                    return;
                } 
                return _(window.fnGetCodeList(optionName))
                    .map((x)=>{
                        return {
                            'code' : x.code,
                            'name' : x.codeName,
                            'order': (x.codeSort||0)
                        }
                    })
                    .orderBy(['order'],['asc'])
                    .value();
            };
            V.filter('dbOptions', optionsFn);
            Vue.prototype.$dbOptions = optionsFn;
        } // end of install
    };
    Vue.use(stiDbTrans);
}
// Preload
// Vue.prototype.$dbTrans = function(){return '';}
export default _installFilter()
 