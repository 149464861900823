<template>
    <div>
        <h4 class="card-title">Company Information</h4>
        <template v-if="page==='info' || page==='confirm'">
            <v-label>What’s the structure of your business?</v-label>
            <v-text-field placeholder="Company type" v-model="companyType" disabled outlined dense></v-text-field>

            <v-label>What’s the name of your business?</v-label>
            <v-text-field placeholder="Company name" v-model="companyName" disabled outlined dense></v-text-field>

            <v-label>Where is your business located?</v-label>
            <v-text-field placeholder="Unit / Lot / Bldg Number" v-model="addr1" disabled outlined dense></v-text-field>
            <v-text-field placeholder="Street / Barangay" v-model="addr2" disabled outlined dense></v-text-field>
            <v-row align="center">
                <v-col class="d-flex py-0" cols="12" sm="12" lg="6">
                    <v-text-field placeholder="Makati City" v-model="city" disabled outlined dense></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="12" lg="6">
                    <v-text-field placeholder="province" v-model="province" disabled outlined dense></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="12" lg="6">
                    <v-text-field placeholder="1226" v-model="postCode" disabled outlined dense></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="12" lg="6">
                    <v-text-field placeholder="country" v-model="country" disabled outlined dense></v-text-field>
                </v-col>
            </v-row>
            <v-label>Company Contact number</v-label>
            <v-text-field placeholder="9xxxxxxxxx" v-model="companyPhoneNoReadOnly" disabled outlined dense></v-text-field>
            <v-label>Which category best matches your business?</v-label>
            <v-text-field placeholder="category" v-model="businessCategory" disabled outlined dense></v-text-field>

            <v-label>What is the nature of your business?</v-label>
            <v-textarea
                rows="5"
                placeholder="What products and/or services do you offer?
    What type of customers do you usually have?
    How do you currently accept payments?"
                v-model="companyIntro"
                disabled outlined dense
            ></v-textarea>

            <v-label>Where can we find your business online?</v-label>
            <v-text-field placeholder="https://www.anyonepay.com" v-model="companyUrl" disabled outlined dense></v-text-field>

            <v-label>Company email</v-label>
            <v-text-field placeholder="business@anyonepay.com" v-model="companyEmail" disabled outlined dense></v-text-field>

            <v-label>Finance email</v-label>
            <p class="red--text mb-0"><small>*To receive payout update</small></p>
            <email-list
                :isModify="false"
                v-bind:emailList="financeEmailList"
                v-on:update:emailList="financeEmailList=$event"
            >
            </email-list>

            <v-label>SEC Certificate of Incorporation</v-label>
            <v-col  cols="12" md="6" >
                <v-btn class="mr-1 mb-1 w-100" @click="downloadImg(businessLicenseImagePath)">Click to Download</v-btn>
            </v-col>

            <v-label>By-Laws</v-label>
            <v-col  cols="12" md="6" >
                <v-btn class="mr-1 mb-1 w-100" @click="downloadImg(representativeIdCardImagePath)">Click to Download</v-btn>
            </v-col>

            <v-label>Articles of Incorporation</v-label>
            <v-col  cols="12" md="6" >
                <v-btn class="mr-1 mb-1 w-100" @click="downloadImg(articlesOfIncorporationImagePath)">Click to Download</v-btn>
            </v-col>

            <v-label>Company Logo Image</v-label>
            <v-col  cols="12" md="6" >
                <v-btn class="mr-1 mb-1 w-100" @click="downloadImg(companyLogoImagePath)">Click to Download</v-btn>
            </v-col>
            <template v-if="page==='info'">
                <v-btn :color="btnSet.modify.color" v-if="!canConfirm" @click="goEditPage('business')" outlined>Modify</v-btn>
                <v-btn :color="btnSet.confirm.color" v-else @click="goConfirmPage('business')" outlined>Confirm</v-btn>
            </template>
            <template v-if="page==='confirm'">
                <change-status
                    :stepStatus="settingConfirmedStatus"
                    paramName="category"
                    paramValue="business"
                    location="settings"
                    v-on:settingsbusiness="goBack">
                </change-status>
            </template>
        </template>
        <!-- ---------------------------------------------------------------------------------------------------------------------- -->
        <template v-if="page==='modify'">
            <v-label>What’s the structure of your business?</v-label>
            <v-select
                :items="companyTypeList" 
                item-text="text"   
                item-value="text"
                label="Select Business Type"
                v-model="companyType"
                dense outlined>
            </v-select>

            <v-label>What’s the name of your business?</v-label>
            <v-text-field placeholder="Company name" v-model="companyName" outlined dense></v-text-field>

            <v-label>Where is your business located?</v-label>
            <v-text-field placeholder="Unit / Lot / Bldg Number" v-model="addr1" outlined dense></v-text-field>
            <v-text-field placeholder="Street / Barangay" v-model="addr2" outlined dense></v-text-field>
            <v-row align="center">
                <v-col class="d-flex py-0" cols="12" sm="12" lg="6">
                    <v-text-field placeholder="Makati City" v-model="city" outlined dense></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="12" lg="6">
                    <v-select
                        :items="provinceList" 
                        item-text="text"
                        item-value="text" 
                        label="Select Province"
                        v-model="province"
                        dense outlined>
                    </v-select>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="12" lg="6">
                    <v-text-field placeholder="1226" v-model="postCode" outlined dense></v-text-field>
                </v-col>
                <v-col class="d-flex py-0" cols="12" sm="12" lg="6">
                    <v-select
                        :items="countryList"
                        item-text="name"
                        item-value="code"
                        label="Select Country"
                        v-model="country"
                        dense outlined>
                    </v-select>
                </v-col>
            </v-row>
            <v-label>Company Contact number</v-label>
            <v-text-field 
                :type="`number`" :inputmode="`numeric`" :pattern="`[0-9]*`" :name="`phone`" placeholder="09xxxxxxxxx (without space or dash)"
                v-model="companyPhoneNo" outlined dense></v-text-field>
            <v-label>Which category best matches your business?</v-label>
            <v-select
                :items="businessCategoryList"
                item-text="text"
                item-value="text"
                label="Select Business Category"
                v-model="businessCategory"
                dense outlined>
            </v-select>

            <v-label>What is the nature of your business?</v-label>
            <v-textarea
                rows="5"
                placeholder="What products and/or services do you offer?
    What type of customers do you usually have?
    How do you currently accept payments?"
                v-model="companyIntro"
                outlined dense
            ></v-textarea>

            <v-label>Where can we find your business online?</v-label>
            <v-text-field placeholder="https://www.anyonepay.com" v-model="companyUrl" outlined dense>
            </v-text-field>

            <v-label>Company email</v-label>
            <v-text-field
                :type="`email`"
                :name="`email`"
                placeholder="business@your-company.com" v-model="companyEmail" outlined dense>
            </v-text-field>

            <v-label>Finance email</v-label>
            <p class="red--text mb-0"><small>*To receive payout update</small></p>
            <email-list
                :isModify="true"
                v-bind:emailList="financeEmailList"
                v-on:update:emailList="financeEmailList=$event"
            >
            </email-list>

            <v-label>SEC Certificate of Incorporation</v-label>
            <input-file 
                allowTypes="image/png,image/jpg,image/gif,image/jpeg,application/pdf"
                fileId="businessLicenseImage"
                v-on:uploadedFileId="onSetFileId"
                v-on:resetFileId="onResetFileId"
                :path="businessLicenseImagePath"
                category="business"
                >
            </input-file>

            <v-label>By-Laws</v-label>
            <input-file 
                allowTypes="image/png,image/jpg,image/gif,image/jpeg,application/pdf"
                fileId="representativeIdCardImage"
                v-on:uploadedFileId="onSetFileId"
                v-on:resetFileId="onResetFileId"
                :path="representativeIdCardImagePath"
                category="business"
                >
            </input-file>

            <v-label>Articles of Incorporation</v-label>
            <input-file 
                allowTypes="image/png,image/jpg,image/gif,image/jpeg,application/pdf"
                fileId="articlesOfIncorporationImage"
                v-on:uploadedFileId="onSetFileId"
                v-on:resetFileId="onResetFileId"
                :path="articlesOfIncorporationImagePath"
                category="business"
                >
            </input-file>

            <v-label>Company Logo Image</v-label>
            <input-file fileId="companyLogoImage" forPublic=true
                v-on:uploadedFileId="onSetFileId"
                v-on:resetFileId="onResetFileId"
                :path="companyLogoImagePath"
                category="business"
                >
            </input-file>
            <div class="mx-auto pt-3" style="max-width: 600px;">
                <v-btn :color="btnSet.modify.color" v-if="!canConfirm" @click="onSubmitBusinessInformation()" outlined block>Modify</v-btn>
            </div>
        </template>
    </div>
</template>

<script>
import companyTypeJson from '@/assets/init/companyType.json';
import businessCategoryJson from '@/assets/init/businessCategory.json';
import provinceJson from '@/assets/init/province.json';
import inputFile from "@/components/input-file.vue";
import changeStatus from "@/components/change-status.vue";
import emailList from "@/components/email-list.vue";
import {ValidateUtil} from '@/plugins/app-util';

export default {
    props: ['page', 'btnSet', 'goEditPage', "goConfirmPage"],
    components: {
        inputFile,
        changeStatus,
        emailList
    },
    data() {
        return {
            companyTypeList: [],
            countryList: [],
            businessCategoryList: [],
            bankNameList: [],
            provinceList: [],
            financeEmailList: [],

            companyType : '',
            companyName : '',
            addr1 : '',
            addr2 : '',
            city : '',
            province : '',
            postCode : '',
            country : '',
            companyPhoneNo: '',
            businessCategory : '',
            companyIntro : '',
            companyUrl : '',
            companyEmail : '',
            businessLicenseImagePath : null,
            businessLicenseImageId : null,
            representativeIdCardImagePath : null,
            representativeIdCardImageId : null,
            articlesOfIncorporationImagePath : null,
            articlesOfIncorporationImageId : null,
            companyLogoImagePath : null,
            companyLogoImageId : null,
            settingConfirmedStatus: 'D',
            canConfirm: false,
        }
    },
    mounted() {
        this.getSelectContents();
        this.getBusinessInformation();
    }, 
    methods: {
        getSelectContents() {
            const vue = this;
            vue.companyTypeList = companyTypeJson;
            vue.countryList = ['Philippines'];
            vue.businessCategoryList= businessCategoryJson;
            vue.provinceList = provinceJson;
        },
        getBusinessInformation() {
            const vue = this;
            const str = APP_APISERVER.uri("get-merchant-business-detail").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));

            APP_SVC.Ajax.request({
                method: 'get',
                url: url,
            }).then(function (res) {
                const rtOrigin = res.result.original;
                const rtCache = res.result.cache;
                vue.settingConfirmedStatus = rtOrigin.settingConfirmedStatus;
                if (rtOrigin.settingConfirmedStatus=='W') vue.canConfirm = true;

                if (vue.page==='confirm') {
                    vue.companyType = rtCache.companyType;
                    vue.companyName = rtCache.companyName;
                    vue.addr1 = rtCache.addr1;
                    vue.addr2 = rtCache.addr2;
                    vue.city = rtCache.city;
                    vue.province = rtCache.province;
                    vue.postCode = rtCache.postCode;
                    vue.country = rtCache.country;
                    vue.companyPhoneNo = ValidateUtil.convertPhoneGlobalToLocal(rtCache.companyPhoneNo);
                    vue.businessCategory = rtCache.businessCategory;
                    vue.companyIntro = rtCache.companyIntro;
                    vue.companyUrl = rtCache.companyUrl;
                    vue.companyEmail = rtCache.companyEmail;
                    vue.financeEmailList = (!rtCache.financeEmails) ? [''] : JSON.parse(rtCache.financeEmails);
                    vue.businessLicenseImagePath = rtCache.businessLicenseImagePath;
                    vue.representativeIdCardImagePath = rtCache.representativeIdCardImagePath;
                    vue.articlesOfIncorporationImagePath = rtCache.articlesOfIncorporationImagePath;
                    vue.companyLogoImagePath = rtCache.companyLogoImagePath;
                    
                } else {
                    vue.companyType = rtOrigin.companyType;
                    vue.companyName = rtOrigin.companyName;
                    vue.addr1 = rtOrigin.addr1;
                    vue.addr2 = rtOrigin.addr2;
                    vue.city = rtOrigin.city;
                    vue.province = rtOrigin.province;
                    vue.postCode = rtOrigin.postCode;
                    vue.country = rtOrigin.country;
                    vue.companyPhoneNo = ValidateUtil.convertPhoneGlobalToLocal(rtOrigin.companyPhoneNo);
                    vue.businessCategory = rtOrigin.businessCategory;
                    vue.companyIntro = rtOrigin.companyIntro;
                    vue.companyUrl = rtOrigin.companyUrl;
                    vue.companyEmail = rtOrigin.companyEmail;
                    vue.financeEmailList = (!rtOrigin.financeEmails) ? [''] : JSON.parse(rtOrigin.financeEmails);
                    vue.businessLicenseImagePath = rtOrigin.businessLicenseImagePath;
                    vue.representativeIdCardImagePath = rtOrigin.representativeIdCardImagePath;
                    vue.articlesOfIncorporationImagePath = rtOrigin.articlesOfIncorporationImagePath;
                    vue.companyLogoImagePath = rtOrigin.companyLogoImagePath;
                }
                    
            }).catch(function (err) {
                console.error('getBusinessInformation:err', err)
                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        onSubmitBusinessInformation() {
            const vue = this;
            const str = APP_APISERVER.uri("get-merchant-business-detail").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));

            if(!vue.validationEmails(vue.getOnlyEmails(vue.financeEmailList))) return;

            APP_SVC.Ajax.request({
                method: 'put',
                url: url,
                data: {
                    companyType : vue.companyType,
                    companyName : vue.companyName,
                    addr1 : vue.addr1,
                    addr2 : vue.addr2,
                    city : vue.city,
                    province : vue.province,
                    postCode : vue.postCode,
                    country : vue.country,
                    companyPhoneNo : ValidateUtil.convertPhoneLocalToGlobal(vue.companyPhoneNo),
                    businessCategory : vue.businessCategory,
                    companyIntro : vue.companyIntro,
                    companyUrl : vue.companyUrl,
                    companyEmail : vue.companyEmail,
                    financeEmails : JSON.stringify(vue.financeEmailList),
                    businessLicenseImageId : vue.businessLicenseImageId,
                    representativeIdCardImageId : vue.representativeIdCardImageId,
                    articlesOfIncorporationImageId : vue.articlesOfIncorporationImageId,
                    companyLogoImageId : vue.companyLogoImageId,
                }
            }).then(function () {
                new RouteUtil().goBack();
                    
            }).catch(function (err) {

                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        //------------------------------------------------------------------------------------------
        getOnlyEmails(emailList) {
            const vue = this;
            let list = emailList;

            for(let i=list.length-1; i>=0; i--) {
                if (list[i] == undefined || list[i].trim() == "") {
                    list.splice(i, 1);
                }
            }
            if ( list.length == 0) list.push('');
            return list;
        },
        validationEmails(list) {
            for(let i=0; i<list.length; i++) {
                if ( !ValidateUtil.checkIsEmail(list[i]) ) {
                    Swal.fire({
                        title: 'please check finance email',
                        type: "warning",
                        showCancelButton: false,
                        confirmButtonClass: "btn-info",
                        confirmButtonText: "OK",
                    });
                    return false;
                }
            }
            return true;
        },
        goBack() {
            new RouteUtil().goBack();
        },
        downloadImg(path) {
            window.open(this.$nasFile(path));
        },
        onSetFileId(data) {
            this[data.fileId+"Id"] = data.resourceId;
        },
        onResetFileId(data) {
            this[data.fileId+"Id"] = null;
        }
    },
    computed : {
        companyPhoneNoReadOnly(){
            return this.$phoneFormat(this.companyPhoneNo);
        }
    }
}
</script>

<style>

</style>