   <template>
    <div>
        <h4 class="card-title">Bank Information</h4>
        <template v-if="page==='info' || page==='confirm'">
            <v-label>Bank Name</v-label>
            <v-text-field placeholder="bankName" v-model="bankName" disabled outlined dense></v-text-field>
            
            <v-label>Account Number</v-label>
            <v-text-field placeholder="" v-model="bankAccountNo" disabled outlined dense></v-text-field>

            <v-label>Account Name</v-label>
            <v-text-field placeholder="No Bank Account Name" v-model="bankAccountName" disabled outlined dense></v-text-field>

            <v-label>A copy of bank statement</v-label>
            <v-col  cols="12" md="6" >
                <v-btn class="mr-1 mb-1 w-100" @click="downloadImg()">Click to Download</v-btn>
            </v-col>
            <template v-if="page==='info'">
                <v-btn :color="btnSet.modify.color" v-if="!canConfirm" @click="goEditPage('bank')" outlined>Modify</v-btn>
            <v-btn :color="btnSet.confirm.color" v-else @click="goConfirmPage('bank')" outlined>Confirm</v-btn>
            </template>
            <template v-if="page==='confirm'">
                <change-status
                    :stepStatus="settingConfirmedStatus"
                    paramName="category"
                    paramValue="bank" 
                    location="settings"
                    v-on:settingsbank="goBack">
                </change-status>
            </template>
        </template>
        <!-- ---------------------------------------------------------------------------------------------------------------------- -->
        <template v-else-if="page==='modify'">
            <v-label>Bank Name</v-label>
            <v-select
                :items="bankNameList"
                item-text="text"
                item-value="text"
                label="Select Bank"
                v-model="bankName"
                dense outlined>
            </v-select>
            
            <v-label>Account Number</v-label>
            <v-text-field placeholder="" v-model="bankAccountNo" outlined dense></v-text-field>

            <v-label>Account Name</v-label>
            <v-text-field placeholder="No Bank Account Name" v-model="bankAccountName" outlined dense></v-text-field>

            <v-label>A copy of bank statement</v-label>
            <input-file fileId="accountCopyImage"
                v-on:uploadedFileId="onSetFileId"
                v-on:resetFileId="onResetFileId"
                :path="accountCopyImagePath"
                category="business"
            >
            </input-file>
            <div class="mx-auto pt-3" style="max-width: 600px;">
                <v-btn :color="btnSet.modify.color" v-if="!canConfirm" @click="onSubmitBankInformation()" outlined block>Modify</v-btn>  
            </div>
        </template>
    </div>
</template>

<script>
import bankNameJson from '@/assets/init/bankName.json';
import inputFile from "@/components/input-file.vue";
import changeStatus from "@/components/change-status.vue";

export default {
    props: ['page', 'btnSet', 'goEditPage', 'goConfirmPage'],
    components: {
        inputFile,
        changeStatus
    },
    data() {
        return {
            bankNameList: [],
            bankName: '',
            bankAccountNo: '',
            bankAccountName: '',
            accountCopyImageId: null,
            accountCopyImagePath: null,
            settingConfirmedStatus: 'D',
            canConfirm: false,
        }
    },
    mounted() {
        this.bankNameList = bankNameJson;
        this.getBankInformation();
    },
    methods: {
        getBankInformation() {
            const vue = this;
            const str = APP_APISERVER.uri("get-merchant-bank-detail").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));

            APP_SVC.Ajax.request({
                method: 'get',
                url: url,
            }).then(function (res) {
                const rtOrigin = res.result.original;
                const rtCache = res.result.cache;
                vue.settingConfirmedStatus = rtOrigin.settingConfirmedStatus;
                if (rtOrigin.settingConfirmedStatus=='W') vue.canConfirm = true;

                if (vue.page==='confirm') {
                    vue.bankName = rtCache.bankName;
                    vue.bankAccountNo = rtCache.bankAccountNo;
                    vue.bankAccountName = rtCache.bankAccountName;
                    vue.accountCopyImagePath = rtCache.accountCopyImagePath;
                } else {
                    vue.bankName = rtOrigin.bankName;
                    vue.bankAccountNo = rtOrigin.bankAccountNo;
                    vue.bankAccountName = rtOrigin.bankAccountName;
                    vue.accountCopyImagePath = rtOrigin.accountCopyImagePath;
                }
                    
            }).catch(function (err) {
                console.error('getBankInformation:err', err)
                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        onSubmitBankInformation() {
            const vue = this;
            const str = APP_APISERVER.uri("get-merchant-bank-detail").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));

            APP_SVC.Ajax.request({
                method: 'put',
                url: url,
                data: {
                    bankName : vue.bankName,
                    bankAccountNo : vue.bankAccountNo,
                    bankAccountName : vue.bankAccountName,
                    accountCopyImageId : vue.accountCopyImageId
                }
            }).then(function () {
                new RouteUtil().goBack();
                    
            }).catch(function (err) {

                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        goBack() {
            new RouteUtil().goBack();
        },
        downloadImg() {
            window.open(this.$nasFile(this.accountCopyImagePath));
        },
        onSetFileId(data) {
            this[data.fileId+"Id"] = data.resourceId;
        },
        onResetFileId(data) {
            this[data.fileId+"Id"] = null;
        },
    }
}
</script>

<style>

</style>