/**
 * Role : Replace DB Constant code to human string redable
 * 
 * - How to use
 * -- In Vue instance as filter
 * {{ value | phoneFormat }}
 * vue.$phoneFormat('6312345678') === '012-345-678'
 * vue.$phoneFormat('6312345678', 'local') === '012-345-678'
 * vue.$phoneFormat('012-345-678', 'db') === '6312345678'
 * vue.$phoneFormat('012-345-678', 'search') === '12345678'
 * 
 * {{ value | datetimeFormat }}
 * vue.$datetimeFormat(new Date()) === '2019-09-20'      // based on local time
 * vue.$datetimeFormat(new Date(), 'db') === '20190920'  // based on local time
 * 
 * 
 */
import Vue from 'vue';

(function(){
    function replaceAll(str, searchStr, replaceStr) {
        return str.split(searchStr).join(replaceStr);
    }
    
    function _installFilterPhoneFormat(){
        var countryFormats = {
            '63' : { prefix: '63', jump : [4,3,4], dash : '-'},
        };
        var stiTrans = {
            install : function(V, options){
                var transFn = function(enumVal, key){
                    var transVal = '';
                    if( ! enumVal ) return transVal;
                    if( key === 'db' ){
                        transVal = enumVal;
                        transVal = replaceAll(transVal, '-', '');
                        transVal = replaceAll(transVal, ' ', '');
                        transVal = replaceAll(transVal, '+', '');
                        return transVal;
                    }
                    if( key === 'search' ){
                        transVal = enumVal;
                        transVal = replaceAll(transVal, '-', '');
                        transVal = replaceAll(transVal, ' ', '');
                        transVal = replaceAll(transVal, '+', '');
                        if( transVal.length>0 && transVal.substr(0,1) === '0'){
                            transVal = transVal.substr(1);
                        }
                        return transVal;
                    }
                    else if( ! key || key == 'local' ){
                        transVal = enumVal;
                        transVal = replaceAll(transVal, '+', '');

                        var selConverter = countryFormats['63'];
                        if( transVal.length>2 ){
                            var preCountry2 = transVal.substr(0,2);
                            var preCountry3 = transVal.substr(0,3);
                            if( countryFormats[preCountry2] ){
                                selConverter = countryFormats[preCountry2];
                            }
                            if( countryFormats[preCountry3] ){
                                selConverter = countryFormats[preCountry3];
                            }
                        }
                        if( transVal.startsWith(selConverter.prefix) ) {
                            transVal = transVal.substr(selConverter.prefix.length);
                        }
                        
                        if( ! transVal.startsWith('0') ) {
                            transVal = '0'+transVal;
                        }
                        var arrRepl = selConverter.jump;
                        var dash = selConverter.dash;
                        for( var a=0; a<arrRepl.length; ++a ){
                            var jump = arrRepl[a];
                            for( var i=a-1; i>=0; --i ){
                                jump += arrRepl[i];
                            }
                            jump += a;
                            if( transVal.length <= (jump) ) continue;
                            transVal = transVal.slice(0, jump)+dash+transVal.slice(jump);
                        }
                    }
                    else{
                        transVal = enumVal;
                    }
                    
                    return transVal;
                };
                V.filter('phoneFormat', transFn);
                Vue.prototype.$phoneFormat = transFn;
            } // end of install
        };
        Vue.use(stiTrans);
    } // _installFilterPhoneFormat

    function _installFilterDateFormat(){
        var showFormat = "E, mon dd, yyyy";
        var dbFormat = "yyyyMMdd";
        var timeFormat = "hh:mm:ss a/p.";
        var tableDateSimpleFormat = "MM/dd";
        var tableDateFormat = "MM/dd/yyyy";
        var tableDateTimeFormat = "MM/dd/yyyy HH:mm:ss";
        var datePickerFormat = "yyyy-MM-dd";
        var datePickerTimeFormat = "yyyy-MM-dd HH:mm:ss";

        var dateUtil = new DateUtil();
        // Available Input cases
        // 1 : Unixtime MSeconds Number/String
        // 2 : Unixtime Seconds String/Number
        // 3 : YYYYmmdd 8 digits String
        var convertStrToDateObject = function(ymd){
            if( ! ymd ) return;
            if( ymd.length === 8 ){ // YYYYmmdd
                var y4 = ymd.substr(0,4);
                var m2 = ymd.substr(4,2);
                var d2 = ymd.substr(6,2);
                y4 = Number(y4), m2 = Number(m2), d2 = Number(d2);
                return new Date(y4, m2-1, d2);
            }
            if( ymd.length === '1571906428'.length ){ // Unixtime Seconds
                return new Date(Number(ymd) * 1000);
            }
            return new Date(Number(ymd));
        }
        // 1 : HHMMSS 6 digits String
        var convertTimeStrToDateObject = function(hms){
            if( ! hms ) return;
            if( hms.length === 6 ){ // YYYYmmdd
                var h2 = hms.substr(0,2);
                var m2 = hms.substr(2,2);
                var s2 = hms.substr(4,2);
                h2 = Number(h2), m2 = Number(m2), s2 = Number(s2);
                return new Date(1970, 0, 1, h2, m2, s2);
            }
            return new Date(Number(ymd));
        }
        var convertNumToDateObject = function(num){
            if( String(num).length == '1571906428'.length ){
                return new Date(num * 1000);
            }
            return new Date(num);
        }
        var stiTrans = {
            install : function(V, options){
                var transFn = function(enumVal, key){
                    var timeObj = null;
                    if( ! enumVal ) return '';
                    if( typeof enumVal === 'string' && isNaN(enumVal) ) 
                        timeObj = new Date(enumVal);
                    else if( typeof enumVal === 'string' ){
                        timeObj = convertStrToDateObject(enumVal);
                        if( ! timeObj ) return '';
                    }else if( typeof enumVal === 'number' ){
                        timeObj = convertNumToDateObject(enumVal);
                    }else if( typeof enumVal === 'object' ){
                        timeObj = new Date(enumVal);
                    }else{
                        return '';
                    }
                    if( key === 'db' ){
                        return dateUtil.format(timeObj, dbFormat);
                    }
                    if( key === 'time-ap' ){
                        return dateUtil.format(timeObj, timeFormat);
                    }
                    if( key === 'date+time-ap' ){
                        return dateUtil.format(timeObj, timeFormat+' '+showFormat);
                    }
                    if( key === 'tableSimpleDate' ){ // 05/15
                        return dateUtil.format(timeObj, tableDateSimpleFormat);
                    }
                    if( key === 'tableDate' ){ // 05/19/2020
                        return dateUtil.format(timeObj, tableDateFormat);
                    }
                    if( key === 'tableDate+time' ){ // 05/19/2020 00:00:00
                        return dateUtil.format(timeObj, tableDateTimeFormat);
                    }
                    if( key === 'datePicker' ){ // 2020-05-19
                        return dateUtil.format(timeObj, datePickerFormat);
                    }
                    if( key === 'datePicker+time' ){ // 2020-05-19 00:00:00
                        return dateUtil.format(timeObj, datePickerTimeFormat);
                    }
                    return dateUtil.format(timeObj, showFormat);
                };
                V.filter('datetimeFormat', transFn);
                Vue.prototype.$datetimeFormat = transFn;
                // time --------------------------------------------------------
                var transFnTime = function(enumVal, key){
                    var timeObj = null;
                    if( ! enumVal ) return '';
                    if( typeof enumVal === 'string' && isNaN(enumVal) ) return '';
                    if( typeof enumVal === 'string' ){
                        timeObj = convertTimeStrToDateObject(enumVal);
                        if( ! timeObj ) return '';
                    }else{
                        return '';
                    }
                    return dateUtil.format(timeObj, timeFormat);
                };
                V.filter('datetimeFormat4Time', transFnTime);
                Vue.prototype.$datetimeFormat4Time = transFnTime;
            } // end of install
        };
        Vue.use(stiTrans);
    } // _installFilterDateFormat

    _installFilterPhoneFormat();
    _installFilterDateFormat();
    
})();
