import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader

if( process.env.NODE_ENV == 'production' ){
    require('vuetify/dist/vuetify.min.css');
}else{
    require('vuetify/dist/vuetify.css');
}

Vue.use(Vuetify)

// // More plugins
import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

// Ref : https://github.com/Alex1990/tiny-cookie#readme
// var VueCookie = require('vue-cookie');
// Vue.use(VueCookie); // $cookie

const opts = {
    icons: {
        iconfont: 'fa'
    },
    theme: {
        themes: {
            light: {
                primary: '#1ba3ce',
                // secondary: '#03a9f4',
                // accent: '#3f51b5',
                // error: '#f44336',
                // warning: '#ff9800',
                // info: '#2196f3',
                // success: '#8bc34a',
            },
        },
        options: {
            customProperties: false,
            minifyTheme: function (css) {
              return process.env.NODE_ENV === 'production'
                ? css.replace(/[\r\n|\r|\n]/g, '')
                : css
            },
        },
    },
}

export default new Vuetify(opts)