<template>
  <div>
    <div class="d-flex flex-row">
      <v-file-input
        hide-details="true"
        class="file_input"
        :id="fileId"
        v-model="fileList[fileId]"
        :accept="allowedTypes"
        placeholder="Select file"
        @click="function($event) { $event.stopPropagation(); }"
        @change="onSelectFile($event, fileId)"
        prepend-icon=''
        outlined
        dense>
      </v-file-input>
      <v-btn 
          v-if="fileList[fileId] && !resourceIdList[fileId]"
          color="info"
          @click="onUploadFile(fileId, forPublic, userSeq)">
          Upload
      </v-btn>
      <v-btn 
          v-if="fileList[fileId] && resourceIdList[fileId]"
          color="error"
          @click="onRemoveFile(fileId, forPublic)">
          Remove
      </v-btn>
    </div>
    <p v-if="path" class="upload-status-display"><small>This is uploaded</small></p>
  </div>
</template>

<script>
export default {
  props: ["fileId", "path", "forPublic", "allowTypes", "maxSize", "userSeq", "reset", "category"],
  data() {
    return {
      fileList: [],
      resourceIdList: [],
      allowedTypes : this.allowTypes ? this.allowTypes.split(",") : [
        "image/png",
        "image/jpg",
        "image/gif",
        "image/jpeg"
      ],
      maxSizeBytes : this.maxSize ? this.maxSize : (1024*1024) // KiloBytes
    };
  },
  watch: {
    reset(value) {  
      if (value) {
        this.$set(this.fileList, this.fileId, null);
        this.$set(this.resourceIdList, this.fileId, null);
      }
    }
  },
  methods: {
    // File { name: "1111.png", lastModified: 1574660252453, size: 371949, type: "image/png" }
    onSelectFile : function(file, fileId){
      const vue = this;
      // Old file remove
      if( this.resourceIdList[fileId] != null ){
        this.onRemoveFile(fileId);
      }
      // Exist file de-selected
      var needTobeReset = false, warn = false;
      if( file ){
        if( ! vue.allowedTypes.includes(file.type) ) warn = needTobeReset = true;
        if( vue.maxSizeBytes < file.size ) warn = needTobeReset = true;
      }else{
        needTobeReset = true;
      }


      if( needTobeReset ){
        vue.$emit("resetFileId", {fileId: fileId});
        vue.$set(vue.fileList, fileId, null);
        vue.$set(vue.resourceIdList, fileId, null);

        warn && Swal.fire({
            title : 'Attachment size exceeds the allowable limit or not allowed type.',
            type  : 'error',
            showCancelButton: false,
            confirmButtonClass: "btn-info",
            confirmButtonText: "OK",
        });
      }
    },
    onUploadFile : function(fileId, forPublic, userSeq){
      const vue = this;
      const seq = userSeq ? userSeq : new RouteUtil().current.param("userSeq")
      const url = forPublic ? APP_APISERVER.uri("post-upload-file-public").asString() : APP_APISERVER.uri("post-upload-merchant-file-private").asString()+"/"+seq;

      const formData = new FormData();
      formData.append('file',vue.fileList[fileId]);
      formData.append('category ',vue.category);
      
      APP_SVC.Ajax.request({
        method: 'post',
        url: url,
        data: formData,
        headers: 
          {
            'Content-Type': 'multipart/form-data'
          }
      }).then(res => {
        const resourceId = res.result.resourceId;
        vue.$emit("uploadedFileId", {"resourceId" : resourceId, "fileId": fileId});
        vue.$set(vue.resourceIdList, fileId, resourceId);
      }).catch(err => {
        Swal.fire({
            title : err.response.result_message || vue.defaultErrorMessage,
            type  : 'error',
            showCancelButton: false,
            confirmButtonClass: "btn-info",
            confirmButtonText: "OK",
        });
      });
    },
    onRemoveFile : function(fileId, forPublic){
      const vue = this;
      const sel = forPublic ? APP_APISERVER.uri("delete-upload-file-public").asString() : APP_APISERVER.uri("delete-upload-merchant-file-private").asString();
      const url = sel+"/"+encodeURIComponent(vue.resourceIdList[fileId]);
      APP_SVC.Ajax.request({
        method: 'delete',
        url: url
      }).then(() => {
        vue.$emit("resetFileId", {fileId: fileId});
        vue.$set(vue.fileList, fileId, null);
        vue.$set(vue.resourceIdList, fileId, null);
      }).catch(err => {
        Swal.fire({
            title : err.response.result_message || vue.defaultErrorMessage,
            type  : 'error',
            showCancelButton: false,
            confirmButtonClass: "btn-info",
            confirmButtonText: "OK",
        });
      });
    }
  },
};
</script>

<style scoped>
.upload-status-display {
  color: #1ba3ce;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  margin-bottom: 20px;
}

.file_input:first-child {
  padding-right: 4px
}
.file_input:first-child:last-child {
  padding-right:0
}

.file-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.file-btn-wrapper >>> .file-btn {
  margin: 8px;
  width: 90px;
  height: 32px;
  border-radius: 2px;
  border: solid 1px #aaaaaa;
  background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0)),
    linear-gradient(to bottom, #ebebeb, #ebebeb);

  font-size: 1.1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #363636;
  border-collapse: collapse;
}
.file-btn-wrapper >>> .file-info {
  font-size: 1.1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ababab;
  margin-left: 10px;
}
.file-suffix {
  border-left: solid 1px #aaaaaa;
  text-align: center;
  min-height: 48px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #8998ac;
  padding: 15px;
}

@media screen and (max-width: 600px) {
  .file-suffix {
    display: none;
  }
  .file-btn-wrapper >>> .file-btn {
    height: 28px;
    font-size: 1rem;
  }
  .file-btn-wrapper >>> .file-info {
    font-size: 1rem;
  }
}
</style>
