<template>
    <div>
        <h4 class="card-title">Payment Capping</h4>
        <v-alert
            border="left"
            colored-border
            elevation="1"
        >
            These values will be applied immediately. Smart and Globe channels will be calculated based on individual and company criteria, while the rest of the channels will be capped based on the company's criteria.
        </v-alert>
        <template v-if="page==='info'">
            <v-simple-table class="mt-5">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Payment No</th>
                            <th class="text-left">Payment</th>
                            <th class="text-left">Standard/Custom</th>
                            <th class="text-left">Daily</th>
                            <th class="text-left">Monthly</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(cap, idx) in cappingCustomList">
                            <tr v-bind:key="idx">
                                <td>{{cap.pgChannel}}</td>
                                <td>{{cap.operatorName}}</td>
                                <td>{{cap.cappingPolicy}}</td>
                                <td>{{cap.capDaily}}</td>
                                <td>{{cap.capMonthly}}</td>
                            </tr>
                        </template>
                    </tbody>
                </template>
            </v-simple-table>
            <v-btn :color="btnSet.modify.color" v-if="!canConfirm" @click="goEditPage('paymentCapping')" outlined>Modify</v-btn>
            <!-- <v-btn :color="btnSet.confirm.color" v-else outlined>Confirm</v-btn>  -->
        </template>
        <template v-else-if="page==='modify'">
            <v-form class="form" ref="form">
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="cappingCustomList"
                    :single-select="false"
                    item-key="pgChannel"
                    :items-per-page="20"
                    show-select
                    hide-default-footer
                    mobile-breakpoint="0"
                    class="payment-table">
                        <template v-slot:item.capDaily="{ item }">
                            <v-text-field v-model="item.capDaily" type="number" :disabled="item.cappingPolicy=='STANDARD'" dense hide-details outlined></v-text-field>
                        </template>
                        <template v-slot:item.capMonthly="{ item }">
                            <v-text-field v-model="item.capMonthly" type="number" :disabled="item.cappingPolicy=='STANDARD'" dense hide-details outlined></v-text-field>
                        </template>
                        <template v-slot:item.cappingPolicy="{ item }">
                            <v-select 
                                :items='[{"text":"Standard", "value":"STANDARD"}, {"text":"Custom", "value":"CUSTOM"}]'
                                item-text="text"
                                item-value="value"
                                v-model="item.cappingPolicy"
                                outlined
                                dense
                                hide-details>
                            </v-select>
                        </template>
                </v-data-table>
                <div class="mx-auto pt-3" style="max-width: 600px;">
                    <v-btn :color="btnSet.modify.color" v-if="!canConfirm" @click="updateCappingCustomList" outlined block>Update</v-btn>
                </div>
            </v-form>
        </template>
    </div>
</template>

<script>
import { FeeUtil } from '@/assets/sti/lib/vue/business-util';

import paymentFeeExtraEditor from '@/components/payment-fee-extra-editor.vue';

export default {
    components: {
        'payment-fee-extra-editor' : paymentFeeExtraEditor,
    },
    props: ['page', 'btnSet', 'goEditPage'],
    data() {
        return {
            b2bCompanySeq:new RouteUtil().current.param('userSeq'),
            selected: [],
            cappingCustomList: [],
            settingConfirmedStatus: 'D',
            canConfirm: false,
            headers: [
                { text: "Payment No", value: "pgChannel", sortable: false, },
                { text: "Payment", value: "operatorName", sortable: false },
                { text: "Standard/Custom", value: "cappingPolicy", sortable: false },
                { text: "Daily", value: "capDaily", sortable: false },
                { text: "Monthly", value: "capMonthly", sortable: false },
            ],
            feePolicyList : [
                {name : 'Standard', value : 'STANDARD'},
                {name : 'Special', value : 'CUSTOM'}
            ],
        }
    },
    mounted() {
        this.getCappingCustomList();
    },
    methods: {
        getCappingCustomList(number){
            const vue = this;
            const pm = {
                pageNo: 1,
                pageSize: 20,
                b2bCompanySeq:vue.b2bCompanySeq,
            }

            APP_SVC.Ajax.request({
                method: 'get',
                url: APP_APISERVER.uri("get-capping-custom-list").asString(),
                params: pm
            }).then(function (res) {
                const rt = res.result;
                vue.cappingCustomList = rt.list;
            }).catch(function (err) {

                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },

        updateCappingCustomList(){
            const vue = this;
            const selectedCapList = vue.selected;

            const str = APP_APISERVER.uri("put-capping-custom-list").asString();

            APP_SVC.Ajax.request({
                method: 'put',
                url: str,
                data: selectedCapList
            }).then(function (res) {
                new RouteUtil().goBack();
            }).catch(function (err) {

                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        }
    }
}
</script>

<style scoped>
</style>