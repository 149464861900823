<template>
    <div>
        <div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true">
        <!-- BEGIN: Main Menu-->
            <div class="navbar-header">
                <ul class="nav navbar-nav flex-row">
                    <li class="nav-item m-auto text-center">
                        <a class="navbar-brand text-center" :href="`${publicPath}index.html`">
                            <img :src="companyLogoImgPath" class="companyLogo mx-auto" width="100%"/>
                        </a>
                    </li>
                    <li class="nav-item m-auto nav-toggle">
                        <h3 class="brand-text mb-0 companyText" v-text="companyName">CompanyName</h3>
                    </li>
                </ul>
            </div>
            <div class="shadow-bottom"></div>
            <div class="main-menu-content">
                <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                    <template v-for="(nav, index) in navItems">
                        <template v-if="nav.name === ''">
                            <div is="main-menu-nav-item" v-bind:key="index"
                                :type="nav.type"
                                :name="companyName"
                                :icon="nav.icon"
                                :link="nav.link"
                                :subs="nav.subs"
                                :active="nav.active"
                                :companyName="companyName">
                            </div>
                        </template>
                        <template v-if="nav.name !== '' ">
                            <div is="main-menu-nav-item"  v-bind:key="index"
                                :type="nav.type"
                                :name="nav.name"
                                :icon="nav.icon"
                                :link="nav.link"
                                :subs="nav.subs"
                                :active="nav.active"
                                :companyName="companyName">
                            </div>
                        </template>
                    </template>
                </ul>
            </div>
        <!-- END: Main Menu -->
        </div>
    </div>
</template>
<script>

import mainMenuNavItem from '@/components/main-menu-nav-item.vue';

var defaultLogoPath = process.env.BASE_URL + 'static/sti/images/logo.png';

var menuJson = require('./main-menu.json');
fnReplaceBaseUrl(menuJson);

export default {
    components: {
        'main-menu-nav-item' : mainMenuNavItem,
    },
    mounted : function(){
    },
    data: function () {
        return {
            publicPath: process.env.BASE_URL,
            companyLogoImgPath : defaultLogoPath,
            companyName : '',
            menus : menuJson,
        }
    },
    computed : {
        navItems(){
            return getMenuTree(this.menus);
        }
    },
    methods : {
    }
}

function getMenuTree(menu_tree){
    // Page Active set by current page link
    (function(tree){
        function shortPathen(fullPath){
            var curPathArr = fullPath.split("/");
            var pathLen = curPathArr.length;
            if( pathLen > 1 ){
                var lnk = '/'+curPathArr.slice( pathLen-2, pathLen ).join('/');
                return lnk;
            }
            return fullPath;
        }
        var curPath = shortPathen( window.location.pathname || '' );
        var linkMatch = _.find(tree, function(menu){
            if( menu.subs.length > 0 ){
                var subFound = _.find(menu.subs, function(sub){
                    var tPath = shortPathen(sub.link || '');
                    return tPath.length > 0 && curPath.endsWith(tPath);
                });
                if( subFound ) return true;
            }
            var tPath = shortPathen(menu.link || '');
            return tPath.length > 0 && curPath.endsWith(tPath);
        });
        if( linkMatch ){
            linkMatch.active = true;
        }
    })(menu_tree);

    return menu_tree;
}

function fnReplaceBaseUrl(tr){
    for(var k in tr){
        var obj = tr[k];
        if( obj['subs'] && obj['subs'].length>0 ){
            fnReplaceBaseUrl(obj['subs']);
            continue;
        }
        if( obj['link'] ){
            obj['link'] = process.env.BASE_URL + obj['link'].substr(1);
        }
    }
}

</script>
<style scoped>
    .main-menu.menu-light .navigation > li {
        padding: 0 5px;
    }
    .v-application ul, .v-application ol {
        padding: 0;
    }
</style>