<template>
    <div>
        <h4 class="card-title">Reward points fees</h4>
        <v-alert
            border="left"
            colored-border
            elevation="1"
        >
            These values will apply within 5~10 minutes.
        </v-alert>
        <template v-if="page==='info'">
            <v-label>Reward points fees</v-label>
            <v-text-field 
                class="w-50"
                placeholder="fee"
                v-model="mileageRate"
                append-outer-icon="fa fa-percent"
                disabled outlined dense>
            </v-text-field>
            <v-btn :color="btnSet.modify.color" v-if="!canConfirm" @click="goEditPage('reward')" outlined>Modify</v-btn>
            <v-btn :color="btnSet.confirm.color" v-else outlined>Confirm</v-btn> 
        </template>

        <template v-else-if="page==='modify'">
            <v-label>Reward points fees</v-label>
            <v-text-field 
                :type="`number`"
                class="w-50"
                v-model="mileageRate"
                append-outer-icon="fa fa-percent"
                outlined dense>
            </v-text-field>
            <div class="mx-auto pt-3" style="max-width: 600px;">
                <v-btn :color="btnSet.modify.color" @click="onSubmitRewardPointRate()" outlined block>Modify</v-btn>
            </div>
        </template>
    </div>
</template>

<script>

export default {
    props: ['page', 'btnSet', 'goEditPage'],
    data() {
        return {
            canConfirm : false,
            mileageRate : 0,
            settingConfirmedStatus: 'D',
        }
    },
    mounted() {
        this.getRewardPointRate();
    },
    
    methods: {
        getRewardPointRate() {
            const vue = this;
            const str = APP_APISERVER.uri("get-merchant-payment-policy-detail").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));

            APP_SVC.Ajax.request({
                method: 'get',
                url: url,
            }).then(function (res) {
                vue.mileageRate = res.result.mileageRate*100 || 0;
                // if (current.settingConfirmedStatus=='W') vue.showConfirmBtnPay=true;
                    
            }).catch(function (err) {
                console.error('getRewardPointRate:err', err)
                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
        onSubmitRewardPointRate() {
            const vue = this;
            const str = APP_APISERVER.uri("get-merchant-payment-policy-detail").asString();
            const url = str.replace("{company_seq}", new RouteUtil().current.param('userSeq'));

            APP_SVC.Ajax.request({
                method: 'put',
                url: url,
                data: {
                    mileageRate: vue.mileageRate/100,
                }
            }).then(function (res) {
                new RouteUtil().goBack();
                    
            }).catch(function (err) {

                Swal.fire({
                    title: err.data && err.data.result_message || APP_SVC.Ajax.errorMessageDefault(),
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonClass: "btn-info",
                    confirmButtonText: "OK",
                });
            });
        },
    }
}
</script>

<style>
    i.v-icon.fa-percent{ 
        font-size: 15px;
    }
</style>