<template>
    <v-alert v-if="stepStatus == 'C' || stepStatus == 'R'"
        :color="cColor"
        :icon="cIcon"
        dark
    >
      {{ cMessage }}
    </v-alert>
</template>

<script>
export default {
    props: ['stepStatus', 'message'],
    data: function() {
        return {
            alertSet : {
                C : {
                    'icon': 'fas fa-check-circle',
                    'color': '#5dcf7a', // green
                },
                R : {
                    'icon': 'fa fa-times-circle',
                    'color': '#ec6060', // red
                }
            }
        }
    },
    computed: {
        cMessage() {
            if (this.stepStatus == 'C') return 'Complete the confirmation';
            if (this.stepStatus == 'R') return this.message;
        },
        cIcon() {
            if (this.stepStatus == 'C' || this.stepStatus == 'R') {
                return this.alertSet[this.stepStatus].icon;
            }
        },
        cColor() {
            if (this.stepStatus == 'C' || this.stepStatus == 'R') {
                return this.alertSet[this.stepStatus].color;
            }
        }
    }
}
</script>

<style>
</style>
