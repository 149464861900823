<template>
    <v-col cols="12" class="pt-0">
        <v-row>
            <template v-for="(item, i) in emailList">
                <v-row v-bind:key="i" ref="emailRow" class="w-100">
                    <v-col cols="10" class="flex-grow-1 flex-shrink-0">
                        <v-text-field
                            v-model="emailList[i]"
                            outlined
                            dense
                            maxlength="50"
                            hide-details
                            :disabled="!isModify"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="2" class="flex-grow-0" v-if="isModify">
                        <v-btn
                            class="error"
                            @click="onClickDeleteForm(i)"
                            v-show="i!=0"
                        >
                        <i class="fa fa-minus"></i>
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-row>
        <v-row class="flex-row mb-2" v-if="isModify">
            <v-btn class="primary mr-3" @click="onClickAddForm" block>
                <i class="fa fa-plus"></i>
            </v-btn>
        </v-row>
    </v-col>
</template>

<script>

export default {
    name: "emailList",
    props: ['isModify', 'emailList'],
    data() {
        return {
            maxEmailCount: 5,
        }
    },
    mounted() {
    },
    methods: {
        onClickAddForm() {
            const vue = this;
            if( vue.emailList.length >= vue.maxEmailCount ) {
                alert("Up to five emails can be registered.");
                return;
            }
            vue.emailList.push("");
            vue.listChanged();
        },
        onClickDeleteForm(itemIndex) {
            const vue = this;
            let list = vue.emailList;
            list.splice(itemIndex, 1);
            vue.listChanged();
        },
        listChanged() {
            const vue = this;
            const list = vue.emailList;
            vue.$emit("update:emailList", list);
        },
    }
}
</script>

<style scoped>
</style>