import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"card-title"},[_vm._v("Payment Capping")]),_c(VAlert,{attrs:{"border":"left","colored-border":"","elevation":"1"}},[_vm._v(" These values will be applied immediately. Smart and Globe channels will be calculated based on individual and company criteria, while the rest of the channels will be capped based on the company's criteria. ")]),(_vm.page==='info')?[_c(VSimpleTable,{staticClass:"mt-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Payment No")]),_c('th',{staticClass:"text-left"},[_vm._v("Payment")]),_c('th',{staticClass:"text-left"},[_vm._v("Standard/Custom")]),_c('th',{staticClass:"text-left"},[_vm._v("Daily")]),_c('th',{staticClass:"text-left"},[_vm._v("Monthly")])])]),_c('tbody',[_vm._l((_vm.cappingCustomList),function(cap,idx){return [_c('tr',{key:idx},[_c('td',[_vm._v(_vm._s(cap.pgChannel))]),_c('td',[_vm._v(_vm._s(cap.operatorName))]),_c('td',[_vm._v(_vm._s(cap.cappingPolicy))]),_c('td',[_vm._v(_vm._s(cap.capDaily))]),_c('td',[_vm._v(_vm._s(cap.capMonthly))])])]})],2)]},proxy:true}],null,false,2526872815)}),(!_vm.canConfirm)?_c(VBtn,{attrs:{"color":_vm.btnSet.modify.color,"outlined":""},on:{"click":function($event){return _vm.goEditPage('paymentCapping')}}},[_vm._v("Modify")]):_vm._e()]:(_vm.page==='modify')?[_c(VForm,{ref:"form",staticClass:"form"},[_c(VDataTable,{staticClass:"payment-table",attrs:{"headers":_vm.headers,"items":_vm.cappingCustomList,"single-select":false,"item-key":"pgChannel","items-per-page":20,"show-select":"","hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.capDaily",fn:function(ref){
var item = ref.item;
return [_c(VTextField,{attrs:{"type":"number","disabled":item.cappingPolicy=='STANDARD',"dense":"","hide-details":"","outlined":""},model:{value:(item.capDaily),callback:function ($$v) {_vm.$set(item, "capDaily", $$v)},expression:"item.capDaily"}})]}},{key:"item.capMonthly",fn:function(ref){
var item = ref.item;
return [_c(VTextField,{attrs:{"type":"number","disabled":item.cappingPolicy=='STANDARD',"dense":"","hide-details":"","outlined":""},model:{value:(item.capMonthly),callback:function ($$v) {_vm.$set(item, "capMonthly", $$v)},expression:"item.capMonthly"}})]}},{key:"item.cappingPolicy",fn:function(ref){
var item = ref.item;
return [_c(VSelect,{attrs:{"items":[{"text":"Standard", "value":"STANDARD"}, {"text":"Custom", "value":"CUSTOM"}],"item-text":"text","item-value":"value","outlined":"","dense":"","hide-details":""},model:{value:(item.cappingPolicy),callback:function ($$v) {_vm.$set(item, "cappingPolicy", $$v)},expression:"item.cappingPolicy"}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"mx-auto pt-3",staticStyle:{"max-width":"600px"}},[(!_vm.canConfirm)?_c(VBtn,{attrs:{"color":_vm.btnSet.modify.color,"outlined":"","block":""},on:{"click":_vm.updateCappingCustomList}},[_vm._v("Update")]):_vm._e()],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }