import Vue from 'vue'

var MODE_DEBUG = false;

// How to Use sample
// Input  : "get-user-info"  : "../../static/sti/json/user/{userId}/{categoryId}" ,
// Output : ../../static/sti/json/user/USERID-A/CATEID-B
// Call   : APP_APISERVER.uri("get-user-info").binds({'userId' : 'USERID-A', 'categoryId' : 'CATEID-B'}).asString();
function addressHandler(data) {
    var d = data;
    // ex) path : /abc/{userId}/list
    function resolver(path) {
        var base = path;
        return {
            asString: function () {
                if (base.startsWith('/')) {
                    return (d.meta['api-baseurl'] || '') + base;
                }
                return base;
            },
            binds: function (uriObj) {
                if (typeof uriObj !== 'object') return this;
                var keys = Object.keys(uriObj);
                for (var k = 0; k < keys.length; ++k) {
                    var key = keys[k];
                    base = base.split('{' + key + '}').join(encodeURIComponent(uriObj[key]));
                }
                return this;
            },
        };
    }
    return {
        uri: function (name) {
            if (!d.uris) return new resolver('');
            var path = d.uris[name];
            if (!path) MODE_DEBUG && console.error('Not Exist URI name defined:', name, 'in ', baseApiServerJsonPath);
            return new resolver(path || '');
        },
        meta: function (key) {
            return d['meta'] && d['meta'][key];
        },
    }
};

var initCdnVueJs = function (baseUrl) {
    var prefix = baseUrl || '';
    Vue.filter('cdnBaseUrl', function (enumVal, key) {
        return prefix + enumVal;
    });
    Vue.prototype.$cdnBaseUrl = function (v) {
        return prefix + v;
    };
};

function replaceMetaKeywords(meta, arr){
    var keys = Object.keys(meta);
    for( var idx in keys ){
        var k = keys[idx];
        var v = meta[k] || '';
        if( typeof k !== 'string' ) continue;

        var arrKeys = Object.keys(arr);
        for( var a in arrKeys ){
            var aKey = arrKeys[a];
            var aVal = arr[aKey];
            if( typeof aKey !== 'string' ) continue;

            arr[aKey] = aVal.replace('%'+k+'%', v);
        }
    }
};

function loadSiteCustomApi(orgApiServerData){
    var sumData = {};
    sumData['meta'] = Object.assign({}, orgApiServerData['meta']);
    sumData['uris'] = Object.assign({}, orgApiServerData['uris']);
    replaceMetaKeywords(sumData['meta'], sumData['uris']);
    var appSvr = addressHandler(sumData);
    initCdnVueJs(appSvr.meta("cdn-baseurl"));
    return appSvr;
};

const merge = require('deepmerge');

function lastMerge(){
    const targetPhase = process.env.VUE_APP_SERVER_MODE;
    const phases = ['loc','dev','stg','prd'];
    
    var phJson = [];
    for( var p=0; p<phases.length; ++p ){
        var ph = phases[p];
        var phFile = ph;
        phJson.push( require('@/assets/sti/json/api-server-'+phFile+'.json') );
        if( ph === targetPhase ) break;
    }
    return merge.all(phJson);
}
const apiServer = loadSiteCustomApi( lastMerge() );

export {
    apiServer
}